import { NavigateFunction } from 'react-router-dom';
import axios from 'axios';

import { IAuthContext } from 'contexts/Auth';
import axiosInstance from './axiosConfig';
import { API_URL } from './constants';

export default class GeneralApi {
  auth: IAuthContext;
  history: NavigateFunction;
  overrideRole: string;

  constructor(auth: IAuthContext, history: NavigateFunction, overrideRole: string = '') {
    this.auth = auth;
    this.history = history;
    this.overrideRole = overrideRole;
  }

  async getFile(path: string) {
    try {
      const result = await axios.get(path, {
        responseType: 'blob',
      });

      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.status === 440) {
        this.auth.signout(() => this.history('/', { replace: true }));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  }

  async get(apiPath: string, params: any = {}) {
    try {
      const result = await axiosInstance.get(`${API_URL}${apiPath}`, {
        params,
      });
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.status === 440) {
        this.auth.signout(() => this.history('/', { replace: true }));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  }

  async post(apiPath: string, data: any) {
    try {
      const result = await axiosInstance.post(`${API_URL}${apiPath}`, data);
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 440) {
        this.auth.signout(() => this.history('/', { replace: true }));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        data: error.response != null ? error.response.data : {},
        status: error.response.status,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  }

  async put(apiPath: string, data: any) {
    try {
      const result = await axiosInstance.put(`${API_URL}${apiPath}`, data);
      return {
        success: true,
        message: 'Pedido correcto',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 440) {
        this.auth.signout(() => this.history('/', { replace: true }));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  }
}
