import React, { useMemo } from 'react';

import LoadingSpinner from 'components/LoadingSpinner';
import { OPTIONS_GRAPH_COLORS, SPINNER_COLORS } from 'utils/enums';
import { classNames } from 'utils/functions';

const colorClasses = {
  [OPTIONS_GRAPH_COLORS.PRIMARY]: {
    title: 'text-primary-base border-primary-base',
    bg: 'bg-primary-base shadow-primary-base/50',
  },
  [OPTIONS_GRAPH_COLORS.ACCENT]: {
    title: 'text-accent border-accent',
    bg: 'bg-accent shadow-accent/50',
  },
  [OPTIONS_GRAPH_COLORS.YELLOW]: {
    title: 'text-yellow-500 border-yellow-500',
    bg: 'bg-yellow-400 shadow-yellow-400/50',
  },
};

export default function OptionsGraphComponent({
  title,
  color,
  data,
  total,
  transform,
  isLoading,
}: {
  title: string;
  color: OPTIONS_GRAPH_COLORS;
  data: any;
  total: number;
  transform: (value: string) => string;
  isLoading: boolean;
}) {
  const options = useMemo(() => {
    return data
      ? Object.keys(data)
          ?.filter((key) => key !== '')
          .sort((a, b) => (data[a] > data[b] ? -1 : data[a] === data[b] ? 0 : 1))
      : [];
  }, [data]);

  return (
    <>
      <div className="flex">
        <div
          className={classNames(
            colorClasses[color].title,
            'mb-4 block rounded-lg border px-4 py-2'
          )}
        >
          {title}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-1 justify-center">
          <LoadingSpinner color={SPINNER_COLORS.PRIMARY} content="Buscando información" />
        </div>
      ) : !data || options.length === 0 ? (
        <div className="flex flex-1 justify-center">No se encontraron datos</div>
      ) : (
        <div className="flex">
          <div className="flex flex-col space-y-1 border-r-2 border-gray-400 pr-3">
            {options.map((key: string) => (
              <p key={key}>{transform(key)}</p>
            ))}
          </div>
          <div className="flex flex-1 flex-col space-y-4 px-3 pt-[6px]">
            {options.map((key: string) => (
              <div
                key={key}
                className={classNames(colorClasses[color].bg, 'h-3 rounded-xl shadow-md')}
                style={{
                  width: `${(data?.[key] / total) * 100}%`,
                }}
              />
            ))}
          </div>
          <div className="flex flex-col space-y-1">
            {options.map((key: string) => (
              <div key={key}>{data?.[key]}</div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
