import React from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import SolidButton from 'components/SolidButton';

export default function EmptyPage() {
  const history = useNavigate();

  return (
    <PrivateRoute>
      <div className="text-[#1B205A]">
        <div className="flex h-full flex-col items-center justify-center py-24">
          <h1 className="mb-8 text-2xl">Página en construcción</h1>
          <SolidButton className="w-auto" onClick={() => history('/users')}>
            Regresar
          </SolidButton>
        </div>
      </div>
    </PrivateRoute>
  );
}
