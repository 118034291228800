import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoginIcon } from '@heroicons/react/outline';

import PublicOnlyRoute from 'components/Navigation/PublicOnlyRoute';
import { showErrorToast, showSuccessToast } from 'components/Toast';
import ExternalHeader from 'components/Navigation/ExternalHeader';
import InputErrorMessage from 'components/InputErrorMessage';
import BaseInput from 'components/Inputs/BaseInput';
import SolidButton from 'components/SolidButton';

import { REQUIRED_RULE } from 'utils/constants';
import { classNames } from 'utils/functions';
import { useAuth } from 'contexts/Auth';

export default function AdminLoginPage() {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useNavigate();
  const auth = useAuth();

  const password = watch('password');
  const [loading, setLoading] = useState(false);

  const login = async (data: any) => {
    setLoading(true);
    let result: any = await auth.signin(data.username, data.password);
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    showSuccessToast(result.message);
    history('/users', { replace: true });
  };

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        rules={rules}
        bgClass="bg-gray-100"
        textUppercase={false}
        {...props}
      />
    );
  };

  return (
    <PublicOnlyRoute isAdmin>
      <div className="min-h-full bg-gray-100">
        <ExternalHeader />
        <div className="mx-auto flex flex-col justify-center py-8 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="mx-auto w-full max-w-md">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Ingresa a tu cuenta de admin
              </h2>
            </div>

            <form onSubmit={handleSubmit(login)} className="mt-8">
              <div className="relative mt-8">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-gray-100 px-2 text-gray-500">Ingrese sus datos</span>
                </div>
              </div>

              <div className="mt-6">
                <div className="flex flex-col space-y-4">
                  {buildBaseInput('Usuario', 'text', 'username', REQUIRED_RULE, {})}

                  <div
                    className={classNames(
                      errors['password']
                        ? 'border-red-300 focus-within:border-red-500 focus-within:ring-red-500'
                        : 'focus-within:ring-primarborder-primary-base border-gray-400 focus-within:border-primary-base',
                      'relative rounded-lg border px-3 py-2 shadow-sm focus-within:ring-1'
                    )}
                  >
                    <div className="peer mt-1">
                      <input
                        id="password_input"
                        type="password"
                        autoComplete="current-password"
                        className="peer block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                        {...register('password')}
                      />
                    </div>
                    <label
                      htmlFor="password"
                      className={`${
                        password !== undefined && password?.length > 0
                          ? '-top-3 left-3 py-1 text-sm text-gray-900'
                          : 'top-3 left-2 text-gray-400 peer-focus-within:-top-3 peer-focus-within:left-3 peer-focus-within:py-1 peer-focus-within:text-sm peer-focus-within:text-gray-900'
                      } pointer-events-none absolute -mt-px rounded-md bg-gray-100 px-2 font-medium transition-all duration-150`}
                    >
                      Contraseña
                    </label>
                    <InputErrorMessage error={errors['password']} />
                  </div>
                </div>

                <div className="mt-6">
                  <SolidButton className="w-full" onClick={handleSubmit(login)} isLoading={loading}>
                    Iniciar sesión <LoginIcon className="ml-2 h-5 w-5" />
                  </SolidButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </PublicOnlyRoute>
  );
}
