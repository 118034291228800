import React from 'react';
import { classNames } from 'utils/functions';

export default function ShadowCard({
  wrapperClassName,
  className,
  contentClassName,
  title,
  children,
}: {
  wrapperClassName?: string;
  className?: string;
  contentClassName?: string;
  title?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={wrapperClassName}>
      <div className={classNames(className, 'py-4 px-2 -mx-1 md:mx-0 shadow-card md:rounded-xl md:py-4 md:px-6')}>
        {title && (
          <div className="border-b border-gray-400 pb-4 md:pb-4">
            <p className="font-bold text-primary-base">{title}</p>
          </div>
        )}
        <div className={classNames(contentClassName, 'mt-6')}>{children}</div>
      </div>
    </div>
  );
}
