import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';

import LoginPage from 'pages/external/Login';
import LandingPage from 'pages/Landing';

import ChangePasswordPage from 'pages/customer/ChangePassword';
import UpdateRecordPage from 'pages/customer/UpdateRecord';
import RecordListPage from 'pages/customer/RecordList';
import DataExportPage from 'pages/customer/DataExport';
import NewRecordPage from 'pages/customer/NewRecord';
import ReportPage from 'pages/customer/Report';
import AlertsPage from 'pages/customer/Alerts';
import HomePage from 'pages/customer/Home';

import ChangePasswordAdminPage from 'pages/admin/ChangePassword';
import AdminLoginPage from 'pages/external/AdminLogin';
import AdminAlertsPage from 'pages/admin/Alerts';
import AdminReportPage from 'pages/admin/Report';
import ContactsPage from 'pages/admin/Contacts';
import LicensesPage from 'pages/admin/Licenses';
import UsersPage from 'pages/admin/Users';
import EmptyPage from 'pages/admin/Empty';

import { ProvideAuth } from 'contexts/Auth';

function App() {
  return (
    <>
      <ProvideAuth prefix="admin">
        <BrowserRouter basename="admin">
          <Routes>
            <Route path="/" element={<Navigate to="/users" />} />
            <Route path="/login" element={<AdminLoginPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/licenses" element={<LicensesPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/report" element={<AdminReportPage />} />
            <Route path="/alerts" element={<AdminAlertsPage />} />
            <Route path="/change_password" element={<ChangePasswordAdminPage />} />

            <Route path="*" element={<EmptyPage />} />
          </Routes>
        </BrowserRouter>
      </ProvideAuth>
      <ProvideAuth>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />

            <Route path="/home" element={<HomePage />} />
            <Route path="/new_record" element={<NewRecordPage />} />
            <Route path="/records" element={<RecordListPage />} />
            <Route path="/record/:code" element={<UpdateRecordPage />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/alerts" element={<AlertsPage />} />
            <Route path="/data" element={<DataExportPage />} />
            <Route path="/change_password" element={<ChangePasswordPage />} />

            {/* <Route path="*" element={<CustomerEmptyPage />} /> */}
          </Routes>
        </BrowserRouter>
      </ProvideAuth>
      <div
        id="toast-container"
        className="fixed bottom-0 left-0 right-0 z-50 sm:bottom-auto sm:left-auto sm:top-20 sm:right-4"
      />
      <meta name="enviroment" content={process.env.REACT_APP_ENVIROMENT} />
    </>
  );
}

export default App;
