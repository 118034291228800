import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import OutlineButton from 'components/OutlineButton';
import BaseInput from 'components/Inputs/BaseInput';
import Table from 'components/Table';

import { RECORD_CONSISTENCY_OPTIONS, RECORD_STATUS_TEXT, REQUIRED_RULE } from 'utils/constants';
import { formatDate, formatNumber } from 'utils/functions';
import PaginationApi from 'utils/paginationApi';
import { RECORD_STATUS } from 'utils/enums';
import { useAuth } from 'contexts/Auth';
import GeneralApi from 'utils/generalApi';

export default function RecordListPage() {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: '',
    },
  });
  const history = useNavigate();
  const auth = useAuth();

  const search = watch('search');

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const recordsApi = useMemo(
    () => new PaginationApi('/records/list', 10, auth, history),
    [auth, history]
  );

  const [loading, setLoading] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [filter, setFilter] = useState({
    general_status: 'valid',
    search: '',
  });
  const [records, setRecords] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const [refresh, setRefresh] = useState(true);

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
        rules={rules}
        {...props}
      />
    );
  };

  const deleteItem = async (e:any) => {
    
    const itemId = e.currentTarget.dataset.id
    console.log('itemId', itemId)

    const confirm = window.confirm('¿Está seguro de eliminar este expediente?')

    if (confirm) {
      try {
        let result: any = await generalApi.post('/records/delete', {
          record: itemId,
        });
  
        console.log('result', result)
        alert('Expediente eliminado correctamente')
      } catch (error) {
        alert('Expediente no pudo ser eliminado')
        console.log('error', error)
      } finally {
        setRefresh(true);
      }
    }
  }

  useEffect(() => {
    if (search === filter.search) return;
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeoutAux = setTimeout(() => {
      setFilter({ ...filter, search });
      setRefresh(true);
    }, 600);
    setSearchTimeout(timeoutAux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <h1 className="mb-4 text-3xl font-bold text-primary-base">EXPEDIENTE EXISTENTE</h1>

        <div className="mb-6 items-center justify-between gap-4 lg:flex">
          <span className="flex-1 text-xl font-bold">EXPEDIENTE VIGENTES</span>
          {buildBaseInput('Buscar por apellido', 'search', 'search', REQUIRED_RULE, {
            parentClass: 'w-full mt-1 lg:mt-0 lg:w-96',
          })}
          <OutlineButton
            size="sm"
            className="w-auto"
            onClick={() => {
              setShowClosed(!showClosed);
              setFilter({ ...filter, general_status: showClosed ? 'valid' : 'closed' });
              setRefresh(true);
            }}
          >
            {showClosed ? 'Mostrar Vigentes' : 'Mostrar Cerrados'}
          </OutlineButton>
        </div>
        <Table
          isLoading={loading}
          setIsLoading={setLoading}
          loadingText="Buscando expedientes"
          columns={[
            {
              title: 'Status',
              dataKey: 'status',
              hidden: true,
              type: 'select',
              list: [
                RECORD_STATUS.NEW,
                RECORD_STATUS.IN_EXCHANGE,
                RECORD_STATUS.IN_LOAN,
                RECORD_STATUS.RESOLVED,
                RECORD_STATUS.NOT_RECOVERED,
                RECORD_STATUS.CHARGED,
                RECORD_STATUS.DISCOUNT,
              ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
              render: (item: any) =>
                item.status ? RECORD_STATUS_TEXT[item.status as RECORD_STATUS] : '-',
            },
            {
              title: 'Código de expediente',
              dataKey: 'code',
              render: (item: any) => (
                <Link
                  to={`/record/${item.code}`}
                  className="text-primary-base underline hover:text-primary-dark"
                >
                  {item.code}
                </Link>
              ),
            },
            {
              title: 'DNI/C.E.',
              dataKey: 'doc_number',
            },
            {
              title: 'Nombres y Apellidos',
              dataKey: 'name',
            },
            {
              title: 'Contingencia',
              dataKey: 'contingency',
              type: 'select',
              list: RECORD_CONSISTENCY_OPTIONS,
            },
            {
              title: 'Fecha Inicio',
              dataKey: 'dm_start_at',
              type: 'date',
              render: (item: any) => formatDate(item.dm_start_at),
            },
            {
              title: 'Fecha Fin',
              dataKey: 'dm_end_at',
              type: 'date',
              render: (item: any) => formatDate(item.dm_end_at),
            },
            {
              title: 'Fecha Canje',
              dataKey: 'exchange_at',
              type: 'date',
              render: (item: any) => (item.exchange_at ? formatDate(item.exchange_at) : '-'),
            },
            {
              title: 'NIT Canje',
              dataKey: 'nitt',
            },
            {
              title: 'Fecha UPE',
              dataKey: 'loan_upe_at',
              type: 'date',
              render: (item: any) => (item.loan_upe_at ? formatDate(item.loan_upe_at) : '-'),
            },
            {
              title: 'NIT UPE',
              dataKey: 'loan_nitt',
            },
            {
              title: 'Importe C.R.',
              dataKey: 'amount',
              type: 'number',
              render: (item: any) => (item.amount > 0 ? `S/ ${formatNumber(item.amount)}` : '-'),
            },
            {
              title: 'Fecha Pago',
              dataKey: 'charged_pay_at',
              type: 'date',
              render: (item: any) => (item.charged_pay_at ? formatDate(item.charged_pay_at) : '-'),
            },
            {
              title: '',
              dataKey: 'delete',
              render: (item: any) => (
                <button className="btn btn-danger" data-id={item._id} onClick={deleteItem}>
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z" /></svg>
                </button>
              ),
            },
          ]}
          initialSort={{
            key: 'dm_start_at',
            order: 'desc',
          }}
          data={records}
          setData={setRecords}
          dataApi={recordsApi}
          extraFilter={filter}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </div>
    </PrivateRoute>
  );
}
