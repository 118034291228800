export default class StorageService {
  static set(key: string, data: any, prefix?: string) {
    return localStorage.setItem(`${prefix ? `${prefix}:` : ''}${key}`, JSON.stringify(data));
  }

  static get(key: string, prefix?: string) {
    return JSON.parse(localStorage.getItem(`${prefix ? `${prefix}:` : ''}${key}`) || '{}');
  }

  static remove(key: string, prefix?: string) {
    return localStorage.removeItem(`${prefix ? `${prefix}:` : ''}${key}`);
  }
}
