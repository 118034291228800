import React from 'react';

export default function Card({
  className,
  title,
  isHidden,
  children,
}: {
  className?: string;
  title?: React.ReactNode;
  isHidden?: boolean;
  children?: React.ReactNode;
}) {
  return isHidden ? null : (
    <div className={className}>
      <div className="mb-2">{title}</div>
      <div className="rounded-xl border border-gray-700 bg-gray-100 py-8 px-4 lg:p-10 lg:pb-8">
        {children}
      </div>
    </div>
  );
}
