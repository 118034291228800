import React from 'react';
import {
  CurrencyDollarIcon,
  ChartBarIcon,
  InformationCircleIcon,
  SwitchHorizontalIcon,
  ClipboardListIcon,
  UsersIcon,
  ShieldCheckIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';

import SidebarItemParent from './SidebarItemParent';
import SidebarItem from './SidebarItem';

import { ISidebarParent } from './interfaces';
import StorageService from 'utils/storage';
import { useAuth } from 'contexts/Auth';

// 'super_admin' 'admin' 'user'
const SIDEBAR_ITEMS: Array<ISidebarParent> = [
  {
    title: 'Inicio',
    name: 'home',
    icon: SwitchHorizontalIcon,
    positions: ['admin'],
    items: [{ title: 'Inicio', name: 'home', path: '/home', positions: ['admin'] }],
  },
  {
    title: 'Nuevo expediente',
    name: 'new_record',
    icon: SwitchHorizontalIcon,
    positions: ['admin'],
    items: [
      { title: 'Nuevo expediente', name: 'new_record', path: '/new_record', positions: ['admin'] },
    ],
  },
  {
    title: 'Buscar expediente',
    name: 'records',
    icon: CurrencyDollarIcon,
    positions: ['admin'],
    items: [
      { title: 'Buscar expediente', name: 'records', path: '/records', positions: ['admin'] },
    ],
  },
  {
    title: 'Indicadores y reportes',
    name: 'report',
    icon: ChartBarIcon,
    positions: ['admin', 'super_admin'],
    items: [
      {
        title: 'Indicadores y reportes',
        name: 'report',
        path: '/report',
        positions: ['admin', 'super_admin'],
      },
    ],
  },
  {
    title: 'Alertas',
    name: 'alerts',
    icon: ClipboardListIcon,
    positions: ['admin', 'super_admin'],
    items: [
      { title: 'Alertas', name: 'alerts', path: '/alerts', positions: ['admin', 'super_admin'] },
    ],
  },
  {
    title: 'Descarga de información',
    name: 'data',
    icon: InformationCircleIcon,
    positions: ['admin'],
    items: [
      { title: 'Descarga de información', name: 'data', path: '/data', positions: ['admin'] },
    ],
  },
  {
    title: 'Contactos',
    name: 'contacts',
    icon: UsersIcon,
    positions: ['super_admin'],
    items: [
      { title: 'Contactos', name: 'contacts', path: '/contacts', positions: ['super_admin'] },
    ],
  },
  {
    title: 'Usuarios',
    name: 'users',
    icon: UsersIcon,
    positions: ['super_admin'],
    items: [{ title: 'Usuarios', name: 'users', path: '/users', positions: ['super_admin'] }],
  },
  {
    title: 'Licencias',
    name: 'licenses',
    icon: ShieldCheckIcon,
    positions: ['super_admin'],
    items: [
      { title: 'Licencias', name: 'licenses', path: '/licenses', positions: ['super_admin'] },
    ],
  },
  {
    title: 'Cambiar contraseña',
    name: 'change_password',
    icon: LockClosedIcon,
    positions: ['admin', 'super_admin'],
    items: [
      {
        title: 'Cambiar contraseña',
        name: 'change_password',
        path: '/change_password',
        positions: ['admin', 'super_admin'],
      },
    ],
  },
];

export default function SidebarItems({
  className,
  onCloseClick,
}: {
  className?: string;
  onCloseClick?: () => void;
}) {
  const auth = useAuth();

  const position = StorageService.get('userData', auth?.isAdmin ? 'admin' : '')?.position;
  const sidebarItems = SIDEBAR_ITEMS.filter((obj) => obj.positions.includes(position)).map(
    (obj) => ({
      ...obj,
      items: obj.items.filter((item) => item.positions.includes(position)),
    })
  );

  return (
    <nav className={className}>
      {sidebarItems.map((obj) => {
        if (obj.items !== undefined && obj.items.length > 1) {
          return (
            <SidebarItemParent
              key={obj.name}
              sidebarItem={obj}
              paths={obj.items.map((item) => item.path)}
            >
              {obj.items.map((item) => {
                return (
                  <SidebarItem key={item.name} path={item.path} onClick={onCloseClick}>
                    {item.title}
                  </SidebarItem>
                );
              })}
            </SidebarItemParent>
          );
        }
        if (!sidebarItems.some((obj2) => obj2.name === obj.name)) return null;
        return (
          <SidebarItem key={obj.items[0]?.name} path={obj.items[0]?.path} onClick={onCloseClick}>
            <>
              {/* <obj.icon
                className={classNames(
                  obj.items[0]?.path === location.pathname
                    ? 'text-gray-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  'mr-3 h-6 w-6 flex-shrink-0'
                )}
                aria-hidden="true"
              /> */}
              {obj.items[0]?.title}
            </>
          </SidebarItem>
        );
      })}
    </nav>
  );
}
