export enum TOAST_KIND {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export enum BUTTON_COLORS {
  PRIMARY = 'primary',
  BLUE = 'blue',
  RED = 'red',
}

export enum SPINNER_COLORS {
  PRIMARY = 'primary',
  WHITE = 'white',
  GREEN = 'green',
}

export enum TABLE_BG_COLORS {
  DEAFULT = 'default',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

export enum OPTIONS_GRAPH_COLORS {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  YELLOW = 'yellow',
}

export enum RECORD_STEPS {
  WORKER = 'worker',
  MEDICAL_REST = 'medical_rest',
  VALIDATION = 'validation',
  DISCOUNT = 'discount',
  IN_LOAN = 'in_loan',
  UPE = 'upe',
  RESOLVED = 'resolved',
  CHARGED = 'charged',
  PARTIAL_REFUND = 'partial_refund',
  NOT_RECOVERED = 'not_recovered',
}

export enum GENERAL_STATUS {
  VALID = 'valid',
  CLOSED = 'closed',
}

export enum RECORD_STATUS {
  NEW = 'new',
  UPE = 'upe',
  IN_EXCHANGE = 'in_exchange',
  IN_LOAN = 'in_loan',
  RESOLVED = 'resolved',
  NOT_RECOVERED = 'not_recovered',
  CHARGED = 'charged',
  DISCOUNT = 'discount',
}

export enum RECORD_STATUS_DETAIL {
  COMPLETED = 'completed',
  MISSING_DATA = 'missing_data',

  PENDING_DOCUMENTATION = 'pending_documentation',
  IN_SUBSIDY = 'in_subsidy',
  PENDING_FIRST_FILE = 'pending_first_file',
  PPENDING_INCOME_UPE = 'pending_income_upe',
  IN_CHARGE = 'in_charge',

  REGISTERED_VIVA = 'registered_viva',
  REGISTERED_CEVIT = 'registered_cevit',
  OBSERVED = 'observed',
  IMECI = 'imeci',
  ENTER_COMECI = 'enter_comeci',
  CITT_ISSUED = 'citt_issued',

  PENDING_UPE = 'pending_upe',
  WITH_OBSERVATION = 'with_observation',
  WITH_PAYMENT_DATE = 'with_payment_date',

  PENDING_RECEPTION = 'pending_reception',
  TO_ENTER_RECONSIDERATION = 'to_enter_reconsideration',

  WAITING_RECONSIDERATION = 'waiting_reconsideration',

  TO_ENTER_APPEAL = 'to_enter_appeal',
  WAITING_APPEAL = 'waiting_appeal',

  JUDICIAL_PROCESS = 'judicial_process',

  LATE_REQUEST = 'late_request',
  NO_SUSTAIN = 'no_sustain',
  ALLOWANCE_EXCEEDED = 'allowance_exceeded',
  NO_RESPONSE = 'no_response',
  QUALIFYING_GUIDE = 'qualifying_guide',
  DECEASED = 'deceased',

  RECOVERED = 'recovered',
  PARTIAL_REFUND = 'partial_refund',
  PARTIAL_RESOLUTION = 'partial_resolution',
}

export enum ALERT_KIND {
  CEVIT_PENDING = 'cevit_pending',
  CEVIT_FIX = 'cevit_fix',
  UPE_PENDING = 'upe_pending',
  RESOLVED_RESPONSE = 'resolved_response',
  RECOVERED = 'recovered',
  COMECI_PROBLEM = 'comeci_problem',
}
