import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import SelectFiltersComponent from 'components/SelectFilters';
import { ITableColumn, ITableFilter } from 'components/Table/interfaces';
import { showErrorToast } from 'components/Toast';
import Table from 'components/Table';

import {
  ALERT_KIND_OPTIONS,
  ALERT_LEVEL_OPTIONS,
  DEFAULT_FILTERS,
  MONTH_OPTIONS,
  YEAR_OPTIONS,
} from 'utils/constants';
import { formatDate, formatNumber } from 'utils/functions';
import { ALERT_KIND, TABLE_BG_COLORS } from 'utils/enums';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

const allFilters: Array<ITableFilter> = [
  {
    key: 'company',
    title: 'Empresa',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: [DEFAULT_FILTERS.all],
  },
  {
    key: 'date_kind',
    title: 'Tipo de Fecha',
    value: DEFAULT_FILTERS.date_kind,
    type: 'select',
    list: [
      { value: 'year_month', name: 'Mes y año' },
      { value: 'range', name: 'Rango' },
    ],
  },
  {
    key: 'date_range',
    title: 'Fecha de Inicio',
    value: DEFAULT_FILTERS.date,
    type: 'date',
  },
  {
    key: 'year',
    title: 'Año',
    value: DEFAULT_FILTERS.year,
    type: 'select',
    list: YEAR_OPTIONS,
  },
  {
    key: 'month',
    title: 'Mes',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: MONTH_OPTIONS,
  },
  {
    key: 'level',
    title: 'Nivel',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: ALERT_LEVEL_OPTIONS,
  },
  {
    key: 'kind',
    title: 'Alerta',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: ALERT_KIND_OPTIONS,
  },
];

const defaultFilters: Array<ITableFilter> = [
  {
    key: 'company',
    title: 'Empresa',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: [DEFAULT_FILTERS.all],
  },
  {
    key: 'date_kind',
    title: 'Tipo de Fecha',
    value: DEFAULT_FILTERS.date_kind,
    type: 'select',
    list: [
      { value: 'year_month', name: 'Mes y año' },
      { value: 'range', name: 'Rango' },
    ],
  },
  {
    key: 'year',
    title: 'Año',
    value: DEFAULT_FILTERS.year,
    type: 'select',
    list: YEAR_OPTIONS,
  },
  {
    key: 'month',
    title: 'Mes',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: MONTH_OPTIONS,
  },
  {
    key: 'level',
    title: 'Nivel',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: ALERT_LEVEL_OPTIONS,
  },
  {
    key: 'kind',
    title: 'Alerta',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: ALERT_KIND_OPTIONS,
  },
];

const alertKindColumns: {
  [key in ALERT_KIND]: Array<ITableColumn>;
} = {
  [ALERT_KIND.CEVIT_PENDING]: [
    {
      title: 'F. Emisión',
      dataKey: 'emitted_at',
      type: 'date',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
      render: (item: any) => (item.emitted_at ? formatDate(new Date(item.emitted_at)) : '-'),
    },
    {
      title: 'F. Límite Canje',
      dataKey: 'exchange_deadline_at',
      type: 'date',
      render: (item: any) =>
        item.exchange_deadline_at ? formatDate(new Date(item.exchange_deadline_at)) : '-',
    },
    {
      title: 'Importe Aprox.',
      dataKey: 'amount',
      type: 'number',
      render: (item: any) => (item.amount > 0 ? `S/ ${formatNumber(item.amount)}` : '-'),
    },
  ],
  [ALERT_KIND.CEVIT_FIX]: [
    {
      title: 'F. de Observación Canje',
      dataKey: 'observation_at',
      type: 'date',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
      render: (item: any) =>
        item.observation_at ? formatDate(new Date(item.observation_at)) : '-',
    },
    {
      title: 'F. Límite para Subsanar Canje',
      dataKey: 'fix_deadline_at',
      type: 'date',
      render: (item: any) =>
        item.fix_deadline_at ? formatDate(new Date(item.fix_deadline_at)) : '-',
    },
    {
      title: 'Importe Aprox.',
      dataKey: 'amount',
      type: 'number',
      render: (item: any) => (item.amount > 0 ? `S/ ${formatNumber(item.amount)}` : '-'),
    },
  ],
  [ALERT_KIND.UPE_PENDING]: [
    {
      title: 'F. Fin de Subsidio',
      dataKey: 'dm_end_at',
      type: 'date',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
      render: (item: any) => (item.dm_end_at ? formatDate(new Date(item.dm_end_at)) : '-'),
    },
    {
      title: 'F. Validación CITT',
      dataKey: 'citt_at',
      type: 'date',
      render: (item: any) => (item.citt_at ? formatDate(new Date(item.citt_at)) : '-'),
    },
    {
      title: 'Importe Total Subsidio (C.R.)',
      dataKey: 'loan_cr_total',
      type: 'number',
      render: (item: any) =>
        item.loan_cr_total > 0 ? `S/ ${formatNumber(item.loan_cr_total)}` : '-',
    },
  ],
  [ALERT_KIND.RESOLVED_RESPONSE]: [
    {
      title: 'F. Recepción Resolución',
      dataKey: 'resolved_received_at',
      type: 'date',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
      render: (item: any) =>
        item.resolved_received_at ? formatDate(new Date(item.resolved_received_at)) : '-',
    },
    {
      title: 'Motivo Resolución',
      dataKey: 'resolved_motive',
    },
    {
      title: 'F. Limite Respuesta Resolucion',
      dataKey: 'resolved_deadline_at',
      type: 'date',
      render: (item: any) =>
        item.resolved_deadline_at ? formatDate(new Date(item.resolved_deadline_at)) : '-',
    },
  ],
  [ALERT_KIND.RECOVERED]: [
    {
      title: 'F. de Cobro',
      dataKey: 'charged_at',
      type: 'date',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
      render: (item: any) => (item.charged_at ? formatDate(new Date(item.charged_at)) : '-'),
    },
    {
      title: 'F. de Pago',
      dataKey: 'charged_pay_at',
      type: 'date',
      render: (item: any) =>
        item.charged_pay_at ? formatDate(new Date(item.charged_pay_at)) : '-',
    },
    {
      title: 'F. Límite Solicitud De Reintegro',
      dataKey: 'refund_deadline_at',
      type: 'date',
      render: (item: any) =>
        item.refund_deadline_at ? formatDate(new Date(item.refund_deadline_at)) : '-',
    },
    {
      title: 'Importe Aprox.',
      dataKey: 'amount',
      type: 'number',
      render: (item: any) => (item.amount > 0 ? `S/ ${formatNumber(item.amount)}` : '-'),
    },
  ],
  [ALERT_KIND.COMECI_PROBLEM]: [
    {
      title: 'Tipo de Contingencia',
      dataKey: 'contingency',
    },
    {
      title: 'Días de Subsidio',
      dataKey: 'year_days',
      backgroundColor: (item: any) =>
        item.level === 'low'
          ? TABLE_BG_COLORS.GREEN
          : item.level === 'medium'
          ? TABLE_BG_COLORS.YELLOW
          : TABLE_BG_COLORS.RED,
    },
  ],
};

export default function AdminAlertsPage() {
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState<any>();
  const [levelsCount, setLevelsCount] = useState<any>({
    low: 0,
    medium: 0,
    high: 0,
    total: 0,
  });

  const [appliedFilters, setAppliedFilters] = useState<Array<ITableFilter>>(defaultFilters);

  const columns: Array<ITableColumn> = useMemo(() => {
    const kind = appliedFilters.find((filter: ITableFilter) => filter.key === 'kind');

    return [
      {
        title: 'Empresa',
        dataKey: 'company.bussiness_name',
      },
      {
        title: 'Código Expediente',
        dataKey: 'code',
      },
      {
        title: 'Apellidos y Nombres',
        dataKey: 'name',
      },
      {
        title: 'F. Inicio de Subsidio',
        dataKey: 'dm_start_at',
        type: 'date',
        render: (item: any) => (item.dm_start_at ? formatDate(new Date(item.dm_start_at)) : '-'),
      },
      {
        title: 'F. Fin de Subsidio',
        dataKey: 'dm_end_at',
        type: 'date',
        render: (item: any) => (item.dm_end_at ? formatDate(new Date(item.dm_end_at)) : '-'),
      },
      {
        title: 'Días Subsidio',
        dataKey: 'dm_days',
        type: 'number',
      },
      ...(alertKindColumns[kind?.value?.value as ALERT_KIND] || []),
    ];
  }, [appliedFilters]);

  const getRecords = async (filters?: Array<ITableFilter>) => {
    setLoading(true);
    let result: any = await generalApi.post('/records/alerts', {
      filter: (filters || appliedFilters).reduce((acc: any, filter) => {
        acc[filter.key] = filter.value.value || filter.value;
        return acc;
      }, {}),
    });
    setLoading(false);
    if (!result.success) return showErrorToast(result.message);
    setRecords(result.data.items);
    setLevelsCount(result.data.levelsCount);
  };

  const getCompanies = async () => {
    let result: any = await generalApi.get('/users/companies');
    if (!result.success) return showErrorToast(result.message);
    setAppliedFilters(
      appliedFilters.map((filter) =>
        filter.key === 'company'
          ? {
              ...filter,
              list: [
                { value: 'all', name: 'Todas' },
                ...result.data.items.map((obj: any) => ({
                  value: obj._id,
                  name: `${obj.ruc || ''} - ${obj.bussiness_name}`,
                })),
              ],
            }
          : filter
      )
    );
  };

  useEffect(() => {
    getRecords();
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <h1 className="text-3xl font-bold text-primary-base">MATRIZ DE ALERTAS</h1>
        <div className="mb-8 items-start justify-between space-y-8 py-10 lg:flex lg:space-y-0 lg:space-x-8">
          <SelectFiltersComponent
            allFilters={allFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            getData={(filters) => getRecords(filters)}
          />

          <div className="overflow-hidden bg-white shadow-card sm:rounded-lg">
            <div className="flex justify-between p-4 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-primary-dark">Total Casos</h3>
              <p>{levelsCount.total}</p>
            </div>
            <div className="border-t border-gray-200">
              <dl className="divide-y divide-gray-200">
                <div className="grid grid-cols-3 gap-4 py-3 px-6">
                  <dt className="text-sm font-medium text-red-500">Alto</dt>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">
                    {levelsCount.high}
                  </dd>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">{`${(
                    (levelsCount.high / levelsCount.total) *
                    100
                  ).toFixed(1)}%`}</dd>
                </div>
                <div className="grid grid-cols-3 gap-4 py-3 px-6">
                  <dt className="text-sm font-medium text-yellow-500">Moderado</dt>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">
                    {levelsCount.medium}
                  </dd>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">{`${(
                    (levelsCount.medium / levelsCount.total) *
                    100
                  ).toFixed(1)}%`}</dd>
                </div>
                <div className="grid grid-cols-3 gap-4 py-3 px-6">
                  <dt className="text-sm font-medium text-green-500">Bajo</dt>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">
                    {levelsCount.low}
                  </dd>
                  <dd className="col-span-1 mt-0 text-center text-sm text-gray-900">{`${(
                    (levelsCount.low / levelsCount.total) *
                    100
                  ).toFixed(1)}%`}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <Table
          checkColumnsUpdate
          isLoading={loading}
          loadingText="Buscando alertas"
          columns={columns}
          data={records}
        />
      </div>
    </PrivateRoute>
  );
}
