import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import BaseInput from 'components/Inputs/BaseInput';
import SolidButton from 'components/SolidButton';

import { REQUIRED_RULE } from 'utils/constants';
import { cleanObject } from 'utils/functions';
import GeneralApi from 'utils/generalApi';
import { IUser } from 'utils/interfaces';
import { useAuth } from 'contexts/Auth';

export default function UserModalContent({
  title,
  actionData,
  onClose,
  onSuccess,
}: {
  title: string;
  actionData?: IUser;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: actionData
      ? {
          ...actionData,
          ...(actionData.company.license || {}),
          date: actionData.company.license?.date
            ? new Date(actionData.company.license?.date).toISOString().split('T')[0]
            : '',
          ruc: actionData.company.ruc || '',
          workers_amount: actionData.company.workers_amount || '',
          bussiness_name: actionData.company.bussiness_name || '',
          address: actionData.company.address || '',
        }
      : {},
  });
  const history = useNavigate();
  const auth = useAuth();

  const unit_cost = watch('unit_cost');
  const maxRecords = watch('max_records');

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);
  const { cost, igv, totalCost } = useMemo(() => {
    const cost = unit_cost * maxRecords;
    const igv = cost * 0.18;
    const totalCost = cost + igv;
    return { cost, igv, totalCost };
  }, [unit_cost, maxRecords]);

  const [loading, setLoading] = useState(false);

  const submitAction = async (data: any) => {
    setLoading(true);
    let result: any = await (actionData
      ? generalApi.put('/users', {
          ...cleanObject(data),
          userId: data._id,
          cost,
        })
      : generalApi.post('/users', { ...cleanObject(data), cost }));
    setLoading(false);
    if (!result.success) return showErrorToast(result.message);
    showSuccessToast(actionData ? 'Cambios guardados' : 'Cuenta creada');
    onSuccess();
  };

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        type={type}
        name={name}
        register={register}
        watch={watch}
        control={control}
        setValue={setValue}
        errors={errors}
        rules={rules}
        {...props}
      />
    );
  };

  useEffect(() => {
    const getLicense = async () => {
      let result: any = await generalApi.get('/licenses');
      if (!result.success) return showErrorToast(result.message);
      setValue('unit_cost', result.data.license.cost);
      setValue('max_records', 100);
      setValue(
        'date',
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
      );
    };
    if (!actionData?.company?.license?.cost) getLicense();
  }, [generalApi, setValue, actionData]);

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <div className="rounded-t-lg bg-primary-base px-4 pb-4 sm:flex sm:items-start sm:p-6 sm:py-4">
        <div className="pt-3 text-center sm:pt-0 sm:text-left">
          <h3 className="text-lg font-medium leading-6 text-white" id="modal-headline">
            {title || 'Confirmar acción'}
          </h3>
        </div>
      </div>
      <div className="sm:flex sm:space-x-4">
        <div className="space-y-4 px-4 py-5 sm:w-1/2 sm:py-6 sm:pl-6">
          <p className="text-lg">Empresa</p>
          {buildBaseInput('RUC', 'number', 'ruc', REQUIRED_RULE, {})}
          {buildBaseInput('Nombre', 'text', 'bussiness_name', REQUIRED_RULE, {})}
          {buildBaseInput('Dirección', 'text', 'address', REQUIRED_RULE, {})}
          {buildBaseInput(
            'Cantidad de trabajadores',
            'number',
            'workers_amount',
            REQUIRED_RULE,
            {}
          )}
          {buildBaseInput('Costo por expediente', 'number', 'unit_cost', REQUIRED_RULE, {
            prefix: '$ ',
          })}
          {buildBaseInput('Máximo de expedientes', 'number', 'max_records', REQUIRED_RULE, {})}
          {buildBaseInput('Fecha de fin', 'date', 'date', REQUIRED_RULE, {})}
          <div className="mx-auto w-fit rounded-lg bg-gray-100 py-4 px-8">
            <p className="text-lg">Costo: $/ {cost.toFixed(2)}</p>
            <p className="text-lg">IGV: $/ {igv.toFixed(2)}</p>
            <p className="text-xl">Total: $/ {totalCost.toFixed(2)}</p>
          </div>
        </div>
        <div className="space-y-4 px-4 py-5 sm:w-1/2 sm:py-6 sm:pr-6">
          <p className="text-lg">Usuario</p>
          {buildBaseInput('DNI', 'text', 'dni', REQUIRED_RULE, {})}
          {buildBaseInput('Nombres', 'text', 'first_name', REQUIRED_RULE, {})}
          {buildBaseInput('Apellido Paterno', 'text', 'last_name_p', REQUIRED_RULE, {})}
          {buildBaseInput('Apellido Materno', 'text', 'last_name_m', REQUIRED_RULE, {})}
          {buildBaseInput('Correo', 'email', 'email', REQUIRED_RULE, {
            textUppercase: false,
          })}
          {buildBaseInput('Celular (Opcional)', 'text', 'phone_number', {}, {})}
          {buildBaseInput('Contraseña', 'password', 'password', actionData ? {} : REQUIRED_RULE, {
            textUppercase: false,
            autoComplete: 'false',
          })}
        </div>
      </div>
      <div className="rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <SolidButton
          size="sm"
          className="sm:ml-3 sm:w-auto"
          isLoading={loading}
          onClick={() => handleSubmit(submitAction)()}
        >
          {actionData ? 'Guardar cambios' : 'Crear'}
        </SolidButton>
        <button
          type="button"
          onClick={onClose}
          className="focus:ring-primary-500 mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancelar
        </button>
      </div>
    </form>
  );
}
