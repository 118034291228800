import { API_URL } from './constants';
import StorageService from './storage';
import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(
  function (config: any) {
    const userKind = StorageService.get('userKind') === 'admin' ? 'admin' : undefined;
    if (StorageService.get('accessToken', userKind) != null)
      config.headers.common['authorization'] = StorageService.get('accessToken', userKind) || '';
    config.headers.common['api-key'] = (typeof StorageService.get('api_key', userKind) ==='string')? StorageService.get('api_key', userKind) :process.env.REACT_APP_API_KEY ;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      const userKind = StorageService.get('userKind') === 'admin' ? 'admin' : undefined;
      if (!StorageService.get('isAuthenticated', userKind)) return Promise.reject(error);
      try {
        const result = await axios.post(`${API_URL}/users/refresh`, {
          _id: StorageService.get('userData', userKind)?._id,
          refreshToken: StorageService.get('refreshToken', userKind),
        });
        StorageService.set('accessToken', result.data.accessToken, userKind);
        StorageService.set('refreshToken', result.data.refreshToken, userKind);
        if (error.config.data) error.config.data = JSON.parse(error.config.data);
        error.config.headers['authorization'] = result.data.accessToken;
        error.config.headers['api-key'] =  StorageService.get('api_key', userKind) || process.env.REACT_APP_API_KEY;
        return instance.request(error.config);
      } catch (err) {
        console.log('Refresh token error:', err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
