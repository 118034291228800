import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import Table from 'components/Table';

import PaginationApi from 'utils/paginationApi';
import { formatDate } from 'utils/functions';
import { useAuth } from 'contexts/Auth';

export default function ContactsPage() {
  const history = useNavigate();
  const auth = useAuth();

  const usersApi = useMemo(
    () => new PaginationApi('/contacts/list', 10, auth, history),
    [auth, history]
  );

  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Array<any>>([]);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <h1 className="mb-4 text-3xl font-bold text-primary-base">CONTACTOS</h1>

        <Table
          isLoading={loading}
          setIsLoading={setLoading}
          loadingText="Buscando contactos"
          columns={[
            {
              title: 'Nombres',
              dataKey: 'name',
            },
            {
              title: 'Apellidos',
              dataKey: 'last_name',
            },
            {
              title: 'Empresa',
              dataKey: 'company',
            },
            {
              title: 'País / Región',
              dataKey: 'location',
            },
            {
              title: 'Teléfono Móvil',
              dataKey: 'phone',
            },
            {
              title: 'Cantidad de Trabajadores',
              dataKey: 'workers',
            },
            {
              title: 'Email',
              dataKey: 'email',
            },
            {
              title: 'Comentarios',
              dataKey: 'comment',
            },
            {
              title: 'Fecha de contacto',
              dataKey: 'created_at',
              type: 'date',
              render: (item: any) => formatDate(new Date(item.created_at), 'dd/MM/yyyy', false),
            },
          ]}
          data={contacts}
          setData={setContacts}
          dataApi={usersApi}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </PrivateRoute>
  );
}
