import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import PrivateRoute from 'components/Navigation/ProtectedRoute';
import BaseInput from 'components/Inputs/BaseInput';
import SolidButton from 'components/SolidButton';

import { REQUIRED_RULE } from 'utils/constants';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

export default function ChangePasswordPage() {
  const history = useNavigate();
  const auth = useAuth();
  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);

  const saveData = async (data: any) => {
    setLoading(true);
    let result: any = await generalApi.put('/users/password', data);
    setLoading(false);
    if (!result.success) return showErrorToast(result.message);
    showSuccessToast('Contraseña cambiada correctamente');
    history('/home');
  };

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        control={control}
        rules={rules}
        {...props}
      />
    );
  };

  return (
    <PrivateRoute>
      <form
        className="mx-auto h-full max-w-7xl px-1 pt-8 text-[#1B205A] lg:pt-24 lg:pb-8"
        onSubmit={handleSubmit(saveData)}
      >
        <div className="flex items-center justify-between">
          <h1 className="mb-4 text-xl font-bold text-primary-base sm:text-3xl">
            CAMBIAR CONTRASEÑA
          </h1>
        </div>
        <p className="mt-8 mb-12">Ingresa tu contraseña actual y la nueva para cambiarla.</p>
        <div className="mx-auto flex max-w-sm flex-col gap-4">
          {buildBaseInput('Contraseña actual', 'password', 'password_now', REQUIRED_RULE, {})}
          {buildBaseInput('Nueva contraseña', 'password', 'password_new', REQUIRED_RULE, {})}

          <div className="mt-6">
            <SolidButton className="w-full" onClick={handleSubmit(saveData)} isLoading={loading}>
              Cambiar contraseña
            </SolidButton>
          </div>
        </div>
      </form>
    </PrivateRoute>
  );
}
