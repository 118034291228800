import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

import { classNames } from 'utils/functions';
import { BUTTON_COLORS } from 'utils/enums';

const colorClasses = {
  primary: 'border-primary-base text-primary-dark hover:bg-primary-base hover:text-white',
  blue: 'border-blue-900 text-blue-900 hover:bg-blue-900 hover:text-white',
  red: 'border-red-600 text-red-600 hover:bg-red-600 hover:text-white',
};

const sizeClasses = {
  sm: 'py-2 px-3',
  md: 'py-2 px-4 sm:text-lg',
  lg: 'py-3 px-4 sm:text-lg',
};

export default function OutlineButton({
  color = BUTTON_COLORS.PRIMARY,
  className = '',
  onClick,
  isLoading,
  isDisabled,
  size = 'md',
  children,
}: {
  color?: BUTTON_COLORS;
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(
        className,
        colorClasses[color],
        sizeClasses[size],
        isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        'flex w-full select-none items-center justify-center rounded-lg border-2 transition-colors duration-300'
      )}
    >
      {isLoading ? <LoadingSpinner /> : children}
    </button>
  );
}
