import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from 'utils/functions';

export default function SidebarItem({
  path,
  children,
  onClick,
}: {
  path: string;
  children?: React.ReactNode;
  onClick?: () => void;
}) {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={classNames(
        location.pathname === path
          ? 'bg-primary-dark'
          : 'hover:bg-primary-dark focus:bg-primary-dark',
        'group flex items-center border-primary-base py-5 px-8 font-medium text-white transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2 focus:ring-offset-gray-100'
      )}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
