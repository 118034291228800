import { NavigateFunction } from 'react-router-dom';

import { ITableSort } from 'components/Table/interfaces';

import { IAuthContext } from 'contexts/Auth';
import axiosInstance from './axiosConfig';
import { API_URL } from './constants';

export default class PaginationApi {
  apiPath: string;
  fullPath: string;
  amountPerPage: number;
  auth: IAuthContext;
  history: NavigateFunction;

  constructor(
    apiPath: string,
    amountPerPage: number,
    auth: IAuthContext,
    history: NavigateFunction
  ) {
    this.apiPath = apiPath;
    this.fullPath = `${API_URL}${apiPath}`;
    this.amountPerPage = amountPerPage;
    this.auth = auth;
    this.history = history;
  }

  async getContent(currentPage: number, filter: any, sort?: ITableSort) {
    try {
      const result = await axiosInstance.post(this.fullPath, {
        pagination: {
          page: currentPage,
          amount: this.amountPerPage,
        },
        filter,
        sort: sort ? sort : {},
      });
      return {
        success: true,
        message: 'Información obtenida',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 440) {
        this.auth.signout(() => this.history('/', { replace: true }));
        return {
          success: false,
          message: 'Sesión finalizada',
        };
      }
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  }
}
