import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { MenuIcon, UserCircleIcon, XIcon } from '@heroicons/react/outline';

import LogoColor from 'img/logo_color@2x.png';

import { classNames } from 'utils/functions';
import StorageService from 'utils/storage';

export default function LandingHeader() {
  const history = useNavigate();

  const navigation = [
    { name: 'Inicio', href: '#home_anchor' },
    { name: 'Plataforma Subsidy', href: '#platform_anchor' },
    { name: 'Precios', href: '#pricing_anchor' },
    { name: 'Ventajas', href: '#features_anchor' },
    { name: 'Clientes', href: '#customers_anchor' },
    { name: 'Contáctanos', href: '#contact_anchor' },
  ];

  return (
    <Disclosure as="nav" className="fixed top-0 z-20 w-full bg-white shadow-md">
      {({ open }: { open: boolean }) => (
        <>
          <div className="relative flex items-center justify-between p-6 sm:space-x-4 sm:py-5 2xl:px-16">
            <div className="flex items-center">
              <img src={LogoColor} className="h-10 sm:h-12" alt="" />
              <div className="ml-32 hidden items-center space-x-4 lg:flex xl:space-x-8">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-center text-lg hover:text-primary-base hover:underline"
                  >
                    {item.name}
                  </a>
                ))}
                {StorageService.get('userData')?.name && (
                  <p className="hidden text-lg xl:block">
                    Hola! {StorageService.get('userData')?.name}
                  </p>
                )}
              </div>
            </div>
            {StorageService.get('userData')?.name ? (
              <button onClick={() => history('/home')}>
                <UserCircleIcon
                  className="hidden h-12 w-12 lg:block"
                  aria-hidden="true"
                  strokeWidth={1}
                />
              </button>
            ) : (
              <button
                onClick={() => history('/login')}
                className="hidden rounded-lg bg-primary-base px-4 py-2 text-white transition-colors hover:bg-primary-dark lg:inline-block 2xl:px-12"
              >
                Iniciar sesión
              </button>
            )}
            <Disclosure.Button className="hover inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white lg:hidden">
              <span className="sr-only">Open main menu</span>
              {open ? (
                <XIcon className="block h-8 w-8" aria-hidden="true" />
              ) : (
                <MenuIcon className="block h-8 w-8" aria-hidden="true" />
              )}
            </Disclosure.Button>
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      false ? 'bg-teal-500 text-gray-800' : 'hover text-gray-600 hover:bg-gray-200',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={false ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}

                {StorageService.get('userData')?.name ? (
                  <Disclosure.Button
                    as="a"
                    href="/home"
                    className={classNames(
                      false ? 'bg-teal-500 text-gray-800' : 'hover text-gray-600 hover:bg-gray-200',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={false ? 'page' : undefined}
                  >
                    Mi Cuenta
                  </Disclosure.Button>
                ) : (
                  <Disclosure.Button
                    as="a"
                    href="/login"
                    className={classNames(
                      false ? 'bg-teal-500 text-gray-800' : 'hover text-gray-600 hover:bg-gray-200',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={false ? 'page' : undefined}
                  >
                    Iniciar sesión
                  </Disclosure.Button>
                )}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
