import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { REQUIRED_RULE } from 'utils/constants';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import BaseInput from 'components/Inputs/BaseInput';
import SolidButton from 'components/SolidButton';

export default function LicenseManageForm() {
  const {
    register,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);

  const submitAction = async (data: any) => {
    setLoading(true);
    let result: any = await generalApi.put('/licenses', data);
    setLoading(false);
    if (!result.success) return showErrorToast(result.data.message);
    showSuccessToast('Cambios guardados');
  };

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        type={type}
        name={name}
        register={register}
        watch={watch}
        control={control}
        setValue={setValue}
        errors={errors}
        rules={rules}
        {...props}
      />
    );
  };

  useEffect(() => {
    const getLicense = async () => {
      let result: any = await generalApi.get('/licenses');
      if (!result.success) return showErrorToast(result.message);
      setValue('cost', result.data.license.cost);
      setValue('max_records', result.data.license.max_records);
    };
    getLicense();
  }, [generalApi, setValue]);

  return (
    <form
      className="mx-auto mt-4 max-w-2xl rounded-xl border border-gray-700 bg-gray-100"
      onSubmit={handleSubmit(submitAction)}
    >
      <div className="flex flex-col lg:flex-row justify-center gap-8 px-4 pt-12 pb-4">
        {buildBaseInput(
          'Costo por expediente',
          'number',
          'cost',
          { REQUIRED_RULE },
          { prefix: '$ ' }
        )}
        {buildBaseInput('Máximo de expedientes', 'number', 'max_records', { REQUIRED_RULE }, {})}
      </div>
      <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <SolidButton
          className="sm:ml-3 sm:w-auto"
          isLoading={loading}
          onClick={() => handleSubmit(submitAction)()}
        >
          Guardar cambios
        </SolidButton>
      </div>
    </form>
  );
}
