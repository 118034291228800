import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import LoadingSpinner from 'components/LoadingSpinner';
import { SPINNER_COLORS } from 'utils/enums';

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

export default function LinearGraphComponent({
  data1,
  data2,
  chartX,
  isLoading,
}: {
  data1: {
    name: string;
    data: Array<any>;
  };
  data2: {
    name: string;
    data: Array<any>;
  };
  chartX: Array<string>;
  isLoading: boolean;
}) {
  const finalData1 = useMemo(
    () => data1.data.map((obj) => (obj.value !== undefined ? obj.value : obj)),
    [data1]
  );
  const finalData2 = useMemo(
    () => data2.data.map((obj) => (obj.value !== undefined ? obj.value : obj)),
    [data2]
  );

  return (
    <>
      <div className="mb-4 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-6">
        <div className="flex items-center rounded-lg border-2 border-gray-300 px-4 py-1.5 text-base">
          <div className="mr-2 h-4 w-4 rounded-full bg-accent" />
          {data1.name}
        </div>
        <div className="flex items-center rounded-lg border-2 border-gray-300 px-4 py-1.5 text-base">
          <div className="mr-2 h-4 w-4 rounded-full bg-primary-base" />
          {data2.name}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-1 justify-center">
          <LoadingSpinner color={SPINNER_COLORS.PRIMARY} content="Buscando información" />
        </div>
      ) : [...finalData1, ...finalData2].reduce((a, b) => a + (b || 0), 0) === 0 ? (
        <div className="flex flex-1 justify-center">No se encontraron datos</div>
      ) : (
        <div className="flex md:h-80 2xl:h-96">
          <Chart
            type="scatter"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  formatter(value, context) {
                    if (value === 0) return null;
                    if (context.dataset.label === 'Cantidad de expedientes') return value;
                    return `S/ ${value}`;
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  position: 'right',
                  ticks: {
                    font: {
                      size: 14,
                    },
                    precision: data1.name === 'Cantidad de expedientes' ? 0 : 1,
                    callback:
                      data1.name === 'Cantidad de expedientes'
                        ? undefined
                        : (value) => {
                            return `S/ ${value}`;
                          },
                  },
                },
                y1: {
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: 14,
                    },
                    callback: (value) => {
                      return `S/ ${value}`;
                    },
                  },
                },
              },
            }}
            data={{
              labels: chartX.filter(
                (_, index) =>
                  finalData1.filter(
                    (_, index) => finalData1[index] !== 0 || finalData2[index] !== 0
                  )[index] !== 0 ||
                  finalData2.filter(
                    (_, index) => finalData1[index] !== 0 || finalData2[index] !== 0
                  )[index] !== 0
              ),
              datasets: [
                {
                  type: 'bar',
                  label: data1.name,
                  data: finalData1
                    ? finalData1.filter(
                        (_, index) => finalData1[index] !== 0 || finalData2[index] !== 0
                      )
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                  //fill: true,
                  borderColor: '#EA0000',
                  //backgroundColor: 'rgb(0, 0, 0, 0.2)',
                  order: 2,

                  backgroundColor: '#B1CA56',
                  yAxisID: 'y',
                  pointStyle: 'star',
                  datalabels: {
                    backgroundColor: 'rgb(177, 202, 86, 0.6)',
                    borderRadius: 4,
                  },
                },
                {
                  type: 'line',
                  label: data2.name,
                  data: finalData2
                    ? finalData2.filter(
                        (_, index) => finalData1[index] !== 0 || finalData2[index] !== 0
                      )
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                  backgroundColor: '#3F92D1',
                  borderColor: 'rgb(63, 146, 209, 0.6)',
                  order: 1,

                  yAxisID: 'y1',
                  datalabels: {
                    backgroundColor: 'rgb(63, 146, 209, 0.9)',
                    borderRadius: 4,
                    color: '#F1F1F1',
                  },
                },
              ],
            }}
          />
        </div>
      )}
    </>
  );
}
