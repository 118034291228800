import React, { Fragment } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon, TrashIcon } from '@heroicons/react/outline';

import { ITableFilter, ITableFilterDate } from 'components/Table/interfaces';
import OutlineButton from 'components/OutlineButton';

import { classNames } from 'utils/functions';
import { DEFAULT_FILTERS } from 'utils/constants';

export default function SelectFiltersComponent({
  allFilters,
  appliedFilters,
  setAppliedFilters,
  getData,
}: {
  allFilters: Array<ITableFilter>;
  appliedFilters: Array<ITableFilter>;
  setAppliedFilters: (filters: Array<ITableFilter>) => void;
  getData: (newFilters?: Array<ITableFilter>) => void;
}) {
  return (
    <div className="flex justify-end">
      <Popover.Group className="flex flex-col flex-wrap gap-y-2 gap-x-4 rounded-lg border-2 border-accent px-2 py-4 lg:flex-row lg:px-6">
        {appliedFilters.map((item) => (
          <Popover key={item.key} className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'ring-2 ring-primary-base' : 'ring-gray-200',
                    'cursor-pointer rounded-lg bg-gray-50 py-2 px-3 text-left outline-none ring-1 transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                  )}
                >
                  {item.title}
                  {item.type === 'date' && (item.value as ITableFilterDate).from
                    ? `: Desde ${(item.value as ITableFilterDate).from}`
                    : ''}
                  {item.type === 'date' && (item.value as ITableFilterDate).to
                    ? `${(item.value as ITableFilterDate).from ? ' hasta' : ': Hasta'}  ${
                        (item.value as ITableFilterDate).to
                      }`
                    : ''}
                  {item.type === 'select' && item.value ? `: ${(item.value as any).name}` : ''}
                </Popover.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover.Panel
                    className={classNames(
                      item.type === 'date' ? 'max-w-sm' : 'max-w-xs',
                      item.type === 'select' ? '' : 'min-w-[120px] lg:w-screen',
                      item.align === 'left' ? 'right-0' : 'left-0',
                      'absolute z-10 mt-3 transform'
                    )}
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative flex items-center rounded-lg bg-white p-2">
                        {item.type === 'date' && (
                          <>
                            <input
                              autoFocus
                              type="date"
                              placeholder="Desde"
                              value={(item.value as ITableFilterDate).from}
                              name={`${item.key}_date_from`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        from: e.target.value,
                                        to: (item.value as ITableFilterDate).to,
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);
                                getData(newFilters);
                              }}
                            />
                            <p className="mx-2">-</p>
                            <input
                              type="date"
                              placeholder="Hasta"
                              value={(item.value as ITableFilterDate).to}
                              name={`${item.key}_date_to`}
                              className={classNames(
                                'peer block w-full rounded-md border-0 p-2 placeholder-gray-500 outline-none transition-all duration-300 focus:ring-2 focus:ring-primary-base'
                              )}
                              onChange={(e) => {
                                const newFilters = appliedFilters.map((filter) => {
                                  if (filter.key === item.key) {
                                    return {
                                      ...filter,
                                      value: {
                                        from: (item.value as ITableFilterDate).from,
                                        to: e.target.value,
                                      },
                                    };
                                  }
                                  return filter;
                                });
                                setAppliedFilters(newFilters);
                                getData(newFilters);
                              }}
                            />
                          </>
                        )}
                        {item.type === 'select' && item.list && (
                          <Menu as="div" className="relative text-left">
                            <div>
                              <Menu.Button as={Fragment}>
                                <OutlineButton className="min-w-[180px]" size="sm">
                                  Elegir opción <ChevronDownIcon className="ml-2 h-5 w-5" />
                                </OutlineButton>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1">
                                  {item.list.map((option) => (
                                    <Menu.Item key={option.value}>
                                      {({ active }) => (
                                        <button
                                          className={`${
                                            active ? 'bg-primary-base text-white' : 'text-gray-900'
                                          } group flex w-full items-center justify-between rounded-md px-2 py-2 text-left text-sm`}
                                          onClick={() => {
                                            let newFilters: Array<ITableFilter> =
                                              appliedFilters.map((filter) => {
                                                if (
                                                  filter.key === item.key &&
                                                  filter.value !== option.value
                                                ) {
                                                  return {
                                                    ...filter,
                                                    value: option,
                                                  };
                                                }
                                                return filter;
                                              });
                                            if (
                                              item.key === 'date_kind' &&
                                              option.value !==
                                                appliedFilters.find((f) => f.key === 'date_kind')
                                                  ?.value?.value
                                            ) {
                                              if (option.value === 'year_month') {
                                                newFilters = allFilters
                                                  .map((filter) =>
                                                    filter.key !== 'date_range'
                                                      ? newFilters.find(
                                                          (f) => f.key === filter.key
                                                        ) ||
                                                        allFilters.find((f) => f.key === filter.key)
                                                      : null
                                                  )
                                                  .filter((f) => f !== null) as Array<ITableFilter>;
                                              } else {
                                                newFilters = allFilters
                                                  .map((filter) =>
                                                    !['year', 'month'].includes(filter.key)
                                                      ? newFilters.find(
                                                          (f) => f.key === filter.key
                                                        ) ||
                                                        allFilters.find((f) => f.key === filter.key)
                                                      : null
                                                  )
                                                  .filter((f) => f !== null) as Array<ITableFilter>;
                                              }
                                            }

                                            setAppliedFilters(newFilters);
                                            getData(newFilters);
                                          }}
                                        >
                                          {option.name}
                                          {(item.value as any).value === option.value && (
                                            <CheckIcon
                                              className={classNames(
                                                active ? 'text-white' : 'text-primary-base',
                                                'h-5 w-5'
                                              )}
                                            />
                                          )}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                        <button
                          className="group ml-2 rounded-lg py-2 px-2 outline-none transition-all duration-300 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-red-500"
                          onClick={() => {
                            let newFilters = [...appliedFilters];
                            newFilters[newFilters.findIndex((filter) => filter.key === item.key)] =
                              {
                                key: item.key,
                                title: item.title,
                                type: item.type,
                                value: DEFAULT_FILTERS[item.key] || DEFAULT_FILTERS.all,
                                list: item.list || [],
                              };
                            if (
                              item.key === 'date_kind' &&
                              newFilters[newFilters.findIndex((filter) => filter.key === item.key)]
                                ?.value?.value === 'range'
                            ) {
                              newFilters = allFilters
                                .map((filter) =>
                                  filter.key !== 'date_range'
                                    ? newFilters.find((f) => f.key === filter.key) ||
                                      allFilters.find((f) => f.key === filter.key)
                                    : null
                                )
                                .filter((f) => f !== null) as Array<ITableFilter>;
                            }
                            setAppliedFilters(newFilters);
                            getData(newFilters);
                          }}
                        >
                          <TrashIcon className="h-5 w-5 text-red-500" />
                        </button>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        ))}
      </Popover.Group>
    </div>
  );
}
