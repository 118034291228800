import React from 'react';
import { Transition } from '@headlessui/react';
import { DocumentDownloadIcon } from '@heroicons/react/outline';

import LoadingSpinner from 'components/LoadingSpinner';

import { SPINNER_COLORS } from 'utils/enums';

export default function Pagination({
  page,
  amountPerPage,
  total,
  maxPage,
  onPreviousPage,
  onNextPage,
  downloadShown,
  downloadAction,
  downloadLoading,
}: {
  page: number;
  amountPerPage: number;
  total: number;
  maxPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  downloadShown: boolean;
  downloadAction?: () => void;
  downloadLoading: boolean;
}) {
  return (
    <Transition
      show={total > 0}
      as="nav"
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Mostrando desde
          <span className="font-medium"> {(page - 1) * amountPerPage + 1}</span> hasta
          <span className="font-medium">
            {' '}
            {page * amountPerPage <= total ? page * amountPerPage : total}
          </span>{' '}
          de
          <span className="font-medium"> {total}</span> resultados
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {downloadShown && downloadAction && (
          <button
            onClick={() => downloadAction()}
            className="mr-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            {downloadLoading ? (
              <LoadingSpinner color={SPINNER_COLORS.PRIMARY} content="Descargando" />
            ) : (
              <>
                Excel
                <DocumentDownloadIcon className="ml-2 h-5 w-5" />
              </>
            )}
          </button>
        )}
        <button
          onClick={() => {
            if (page === 1) return;
            onPreviousPage();
          }}
          disabled={page <= 1}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Anterior
        </button>
        <button
          onClick={() => {
            if (page === maxPage) return;
            onNextPage();
          }}
          disabled={page >= maxPage}
          className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Siguiente
        </button>
      </div>
    </Transition>
  );
}
