import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';

export default function SidebarItemParent({
  sidebarItem,
  paths,
  children,
  ...rest
}: {
  sidebarItem: any;
  paths: string[];
  children: React.ReactNode;
}) {
  const location = useLocation();
  const isActive = paths.includes(location.pathname);

  const [isOpen, setIsOpen] = useState(isActive);

  return (
    <div>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`${
          isActive ? 'bg-primary-dark' : 'hover:bg-primary-dark focus:bg-primary-dark'
        } group flex w-full items-center border-teal-600 px-2 py-5 font-medium text-white transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2 focus:ring-offset-gray-100`}
        {...rest}
      >
        {/* <sidebarItem.icon
          className={classNames(
            sidebarItem.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 h-6 w-6 flex-shrink-0'
          )}
          aria-hidden="true"
        /> */}
        {sidebarItem.title}
        <ChevronRightIcon
          className={`${
            isOpen ? 'rotate-90' : ''
          } ml-5 h-5 w-5 transform text-white transition-all duration-150 ease-in-out`}
        />
      </button>

      <Transition
        show={isOpen}
        className="pt-1"
        enter="transition ease-out duration-100"
        enterFrom="scale-95"
        enterTo="scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="scale-100"
        leaveTo="scale-95"
      >
        {children}
      </Transition>
    </div>
  );
}
