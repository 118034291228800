import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, UserCircleIcon, XIcon } from '@heroicons/react/outline';

import SidebarItems from 'components/Sidebar/SidebarItems';
import LogoColor from 'img/logo_color@2x.png';

import { classNames } from 'utils/functions';
import { useAuth } from 'contexts/Auth';

export default function AdminLayout({ children }: { children?: JSX.Element }) {
  const history = useNavigate();
  const auth = useAuth();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(false);

  return (
    <>
      <div className="min-h-full bg-white">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="mb-2 flex flex-shrink-0 items-center">
                  <button onClick={() => history('/')}>
                    <img className="h-14 w-auto" src={LogoColor} alt="Workflow" />
                  </button>
                </div>
                <div className="mt-2 h-0 flex-1 overflow-y-auto bg-primary-base">
                  <nav className="space-y-1">
                    <SidebarItems
                      onCloseClick={() => setSidebarOpen(!sidebarOpen)}
                      className="space-y-3"
                    />
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true" />
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="sticky top-0 z-20 flex h-16 flex-shrink-0 items-center bg-white shadow">
          <div className="flex flex-shrink-0 items-center px-4">
            <button
              type="button"
              className="p-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-base md:hidden"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden p-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-base md:inline-block"
              onClick={() => setDesktopSidebarOpen(!desktopSidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button onClick={() => history('/')}>
              <img className="h-14 p-2" src={LogoColor} alt="Workflow" />
            </button>
          </div>
          <div className="hidden flex-1 items-center justify-end px-8 lg:flex">
            <div className="text-right flex flex-col justify-center">
              <p>Usuario</p>
              <button
                className="text-sm text-red-500"
                onClick={() => auth.signout(() => history('/login', { replace: true }))}
              >
                Cerrar sesión
              </button>
            </div>
            <UserCircleIcon className="ml-4 hidden h-8 w-8 lg:block" strokeWidth={1} />
          </div>
        </div>
        <Transition show={desktopSidebarOpen} as={Fragment}>
          <div className="z-10 hidden md:fixed md:bottom-0 md:top-16 md:flex md:w-64 md:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-primary-base">
              <div className="flex flex-grow flex-col">
                <nav className="flex-1 space-y-1">
                  <SidebarItems />
                </nav>
              </div>
            </div>
          </div>
        </Transition>
        <div
          className={classNames(
            desktopSidebarOpen ? 'md:pl-64' : '',
            'flex min-h-full flex-1 flex-col transition-all duration-300'
          )}
        >
          <main className="min-h-full flex-1 bg-white">{children}</main>
        </div>
      </div>
    </>
  );
}
