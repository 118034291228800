import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';

export default function PublicOnlyRoute({
  isAdmin = false,
  children,
}: {
  isAdmin?: boolean;
  children: JSX.Element;
}) {
  const location = useLocation();
  const auth = useAuth();

  if (auth.isAuthenticated)
    return (
      <Navigate replace to={isAdmin ? '/users' : '/records'} state={{ from: location }} />
    );
  return children;
}
