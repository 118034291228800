import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import UserModalContent from './ActionModalContent';
import SolidButton from 'components/SolidButton';
import Table from 'components/Table';
import Modal from 'components/Modal';

import PaginationApi from 'utils/paginationApi';
import { formatDate } from 'utils/functions';
import { IUser } from 'utils/interfaces';
import { useAuth } from 'contexts/Auth';

export default function UsersPage() {
  const history = useNavigate();
  const auth = useAuth();

  const usersApi = useMemo(
    () => new PaginationApi('/users/list', 10, auth, history),
    [auth, history]
  );

  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<IUser>>([]);

  const [isActionOpen, setIsActionOpen] = useState(false);
  const [actionData, setActionData] = useState<IUser | undefined>(undefined);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <Modal size="large" isOpen={isActionOpen} onClose={() => setIsActionOpen(false)}>
          <UserModalContent
            title={actionData ? 'Editar empresa' : 'Crear empresa'}
            actionData={actionData}
            onClose={() => setIsActionOpen(false)}
            onSuccess={() => {
              setRefresh(true);
              setIsActionOpen(false);
            }}
          />
        </Modal>
        <div className="mb-3 flex items-center justify-between">
          <h1 className="text-3xl font-bold text-primary-base">USUARIOS</h1>
          <SolidButton className="w-auto" onClick={() => setIsActionOpen(true)}>
            Agregar empresa
          </SolidButton>
        </div>

        <Table
          isLoading={loading}
          setIsLoading={setLoading}
          loadingText="Buscando usuarios"
          columns={[
            {
              title: 'RUC',
              dataKey: 'company.ruc',
              disableSort: true,
            },
            {
              title: 'Empresa',
              dataKey: 'company.bussiness_name',
              disableSort: true,
            },
            {
              title: 'Dirección',
              dataKey: 'company.address',
              disableSort: true,
            },
            {
              title: 'Trabajadores',
              dataKey: 'company.workers_amount',
              disableSort: true,
              type: 'number',
            },
            {
              title: 'Nombres',
              dataKey: 'first_name',
            },
            {
              title: 'Apellidos',
              dataKey: 'last_name_p',
              type: 'combo',
              list: ['last_name_p', 'last_name_m'],
              render: (item: IUser) => `${item.last_name_p} ${item.last_name_m}`,
            },
            {
              title: 'DNI',
              dataKey: 'dni',
            },
            {
              title: 'Email',
              dataKey: 'email',
            },
            {
              title: 'Licencia',
              dataKey: 'company.license',
              disableSort: true,
              render: (item: IUser) =>
                item.company?.license
                  ? `${item.company.yearly_record_count || 0} de ${
                      item.company.license.max_records
                    }`
                  : '',
            },
            {
              title: 'Fecha de creación',
              dataKey: 'created_at',
              type: 'date',
              render: (item: IUser) => formatDate(new Date(item.created_at), 'dd/MM/yyyy', false),
            },
            {
              title: 'Acciones',
              dataKey: 'id',
              disableSort: true,
              render: (item: IUser) => (
                <div className="flex items-center justify-center">
                  <SolidButton
                    className="w-auto"
                    size="sm"
                    onClick={() => {
                      setActionData(item);
                      setIsActionOpen(true);
                    }}
                  >
                    Editar
                  </SolidButton>
                </div>
              ),
            },
          ]}
          data={users}
          setData={setUsers}
          dataApi={usersApi}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </PrivateRoute>
  );
}
