import React from 'react';
import { Control, FieldValues, UseFormWatch } from 'react-hook-form';

import InputErrorMessage from 'components/InputErrorMessage';
import { classNames } from 'utils/functions';
import CustomSelect from './CustomSelect';

export default function BaseInput({
  // Input
  idPrefix = '',
  label,
  placeholder = label,
  type = 'text',
  options,
  cornerAction,
  bgClass = 'bg-white',
  helperText,
  buttonContent,
  buttonAction,
  prefix,
  disabled,
  position,
  onChange,
  textUppercase = true,
  // Form
  name,
  register,
  errors,
  watch,
  setValue,
  control,
  rules = {},
  inputRef,
  // Classes
  parentClass = '',
  labelClass = '',
  inputParentClass = '',
  inputClass = '',
  ...props
}: {
  idPrefix: string;
  label?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  options?: any;
  cornerAction?: any;
  bgClass?: string;
  helperText?: string;
  buttonContent?: any;
  buttonAction?: any;
  prefix?: any;
  disabled?: boolean;
  position?: 'bottom' | 'top';
  onChange?: any;
  textUppercase?: boolean;

  name?: string;
  register: any;
  errors: any;
  control?: Control<FieldValues, any>;
  watch?: UseFormWatch<FieldValues>;
  setValue?: any;
  rules?: any;
  inputRef?: any;

  parentClass: string;
  labelClass: string;
  inputParentClass?: string;
  inputClass?: string;
}) {
  const inputId = `inp_${idPrefix}${idPrefix !== '' ? '_' : ''}${name}`;
  const value = watch?.(name || '');

  return (
    <div
      className={classNames(
        parentClass,
        bgClass,
        disabled ? 'text-gray-400' : 'text-gray-900',
        errors[name || '']
          ? 'border-red-300 focus-within:border-red-500 focus-within:ring-red-500'
          : 'border-gray-400 focus-within:border-primary-base focus-within:ring-primary-base',
        type === 'select' || buttonContent ? 'pl-3' : 'px-3',
        buttonContent ? '' : 'py-2',
        'relative h-[fit-content] rounded-lg border shadow-sm focus-within:ring-1'
      )}
    >
      <div className={classNames(buttonContent || prefix ? 'flex items-center' : '', 'peer')}>
        {prefix && <span className="mr-2 mt-1 text-gray-500">{prefix}</span>}
        <div
          className={classNames(
            inputParentClass,
            buttonContent ? 'flex-grow py-2' : '',
            prefix ? 'flex-grow' : '',
            'relative mt-1'
          )}
        >
          {type === 'select' ? (
            control ? (
              <CustomSelect
                name={name || ''}
                control={control}
                value={value}
                options={options}
                rules={rules}
                disabled={disabled}
                position={position || 'bottom'}
                onChange={onChange}
              />
            ) : (
              <p>Invalid select</p>
            )
          ) : type === 'textarea' ? (
            <textarea
              id={inputId}
              name={name}
              disabled={disabled}
              ref={inputRef ? inputRef : undefined}
              className={classNames(
                inputClass,
                bgClass,
                'peer block w-full border-0 p-0 placeholder-gray-500 focus:ring-0'
              )}
              {...register(name, rules)}
              {...props}
              onChange={(e) => {
                const newValue = e.target.value;
                e.target.value = textUppercase
                  ? newValue?.toString()?.toUpperCase()
                  : newValue?.toString();
                setValue?.(
                  name,
                  textUppercase ? newValue?.toString()?.toUpperCase() : newValue?.toString()
                );
              }}
            />
          ) : (
            <input
              type={type}
              id={inputId}
              name={name}
              disabled={disabled}
              ref={inputRef ? inputRef : undefined}
              className={classNames(
                inputClass,
                bgClass,
                'peer block w-full border-0 p-0 placeholder-gray-500 focus:ring-0'
              )}
              {...register(name, rules)}
              {...props}
              onChange={(e) => {
                const newValue = e.target.value;
                e.target.value = textUppercase
                  ? newValue?.toString()?.toUpperCase()
                  : newValue?.toString();
                setValue?.(
                  name,
                  textUppercase ? newValue?.toString()?.toUpperCase() : newValue?.toString()
                );
              }}
            />
          )}
        </div>
        {buttonContent && (
          <button
            type="button"
            className="relative inline-flex items-center space-x-2 mx-1 py-2 rounded-lg  bg-gray-50 px-4 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-base focus:outline-none focus:ring-2 focus:ring-primary-base"
            onClick={buttonAction}
          >
            {buttonContent}
          </button>
        )}
      </div>
      <label
        htmlFor={inputId}
        className={`${labelClass} ${cornerAction ? 'flex justify-between' : 'inline-block'} ${
          value !== undefined && (typeof value !== 'string' || value?.length > 0)
            ? '-top-3 left-3 py-1 text-sm text-gray-900'
            : 'top-3 left-2 text-gray-400 peer-focus-within:-top-3 peer-focus-within:left-3 peer-focus-within:py-1 peer-focus-within:text-sm peer-focus-within:text-gray-900'
        } ${bgClass} pointer-events-none absolute -mt-px rounded-md px-2 font-medium transition-all duration-150`}
      >
        {label}
        {cornerAction && (
          <button
            type="button"
            className="rounded-lg text-primary-base outline-none transition-all hover:underline focus:ring-2 focus:ring-primary-base focus:ring-offset-2"
            onClick={cornerAction?.onClick}
          >
            {cornerAction?.text}
          </button>
        )}
      </label>
      {helperText && <p className="mt-1 border-t border-gray-100 pt-1 text-xs">{helperText}</p>}
      <InputErrorMessage error={errors[name || '']} />
    </div>
  );
}
