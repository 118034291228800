import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import LoadingSpinner from 'components/LoadingSpinner';
import { SPINNER_COLORS } from 'utils/enums';

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

export default function DoughnutGraphComponent({
  labels,
  colors,
  data,
  isLoading,
}: {
  labels: Array<string>;
  colors: Array<string>;
  data: Array<number>;
  isLoading: boolean;
}) {
  return (
    <>
      {isLoading ? (
        <div className="flex flex-1 justify-center">
          <LoadingSpinner color={SPINNER_COLORS.PRIMARY} content="Buscando información" />
        </div>
      ) : data.reduce((a, b) => a + b, 0) === 0 ? (
        <div>No se encontraron datos</div>
      ) : (
        <div className="max-w-sm">
          <Doughnut
            options={{
              responsive: true,
              cutout: '70%',
              layout: {
                padding: 40,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
                datalabels: {
                  anchor: 'start',
                  font: {
                    size: 14,
                    weight: 'bold',
                  },
                  padding: 4,
                  backgroundColor: '#fefefe',
                  borderRadius: 8,
                  borderWidth: 2,
                  borderColor: (context) => colors[context.dataIndex],
                  formatter: (value, context) => {
                    return `${((value * 100) / data.reduce((a, b) => a + b, 0)).toFixed()}%\n${
                      labels[context.dataIndex]
                    }\n${value}`;
                  },
                },
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  label: 'Dataset 1',
                  data: data || [0, 0],
                  backgroundColor: colors,
                },
              ],
            }}
          />
        </div>
      )}
    </>
  );
}
