import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DocumentIcon,
  UserCircleIcon,
  ChartBarIcon,
  ClipboardListIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';

import DoughnutGraphComponent from 'components/Graphs/DoughnutGraph';
import OptionsGraphComponent from 'components/Graphs/OptionsGraph';
import LinearGraphComponent from 'components/Graphs/LinearGraph';
import PrivateRoute from 'components/Navigation/ProtectedRoute';
import SelectFiltersComponent from 'components/SelectFilters';
import { ITableFilter } from 'components/Table/interfaces';
import { showErrorToast } from 'components/Toast';
import ShadowCard from 'components/ShadowCard';

import {
  DEFAULT_FILTERS,
  MONTH_OPTIONS,
  RECORD_STATUS_DETAIL_TEXT,
  RECORD_STATUS_OPTIONS,
  RECORD_STATUS_TEXT,
  STATUS_OPTIONS,
  YEAR_OPTIONS,
} from 'utils/constants';
import { OPTIONS_GRAPH_COLORS, RECORD_STATUS, RECORD_STATUS_DETAIL } from 'utils/enums';
import { classNames } from 'utils/functions';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

const stats = [
  {
    id: 1,
    name: 'Expedientes',
    field: 'amount',
    icon: DocumentIcon,
  },
  {
    id: 2,
    name: 'Días de subsidio',
    field: 'totalDays',
    icon: ClipboardListIcon,
  },
  {
    id: 3,
    name: 'Importe Total',
    field: 'total',
    icon: ChartBarIcon,
  },
  {
    id: 4,
    name: 'Total de trabajadores',
    field: 'workers',
    icon: UserCircleIcon,
  },
  {
    id: 5,
    name: 'Recuperación',
    field: 'recovery',
    icon: ShieldCheckIcon,
  },
];

const allFilters: Array<ITableFilter> = [
  {
    key: 'company',
    title: 'Empresa',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: [DEFAULT_FILTERS.all],
  },
  {
    key: 'report_type',
    title: 'Tipo',
    value: DEFAULT_FILTERS.report_type,
    type: 'select',
    list: STATUS_OPTIONS,
  },
  {
    key: 'date_kind',
    title: 'Tipo de Fecha',
    value: DEFAULT_FILTERS.date_kind,
    type: 'select',
    list: [
      { value: 'year_month', name: 'Mes y año' },
      { value: 'range', name: 'Rango' },
    ],
  },
  {
    key: 'date_range',
    title: 'Rango',
    value: DEFAULT_FILTERS.date,
    type: 'date',
    align: 'left',
  },
  {
    key: 'year',
    title: 'Año',
    value: DEFAULT_FILTERS.year,
    type: 'select',
    list: YEAR_OPTIONS,
  },
  {
    key: 'month',
    title: 'Mes',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: MONTH_OPTIONS,
    align: 'left',
  },
];

const defaultFilters: Array<ITableFilter> = [
  {
    key: 'company',
    title: 'Empresa',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: [DEFAULT_FILTERS.all],
  },
  {
    key: 'report_type',
    title: 'Tipo',
    value: DEFAULT_FILTERS.report_type,
    type: 'select',
    list: STATUS_OPTIONS,
  },
  {
    key: 'date_kind',
    title: 'Tipo de Fecha',
    value: DEFAULT_FILTERS.date_kind,
    type: 'select',
    list: [
      { value: 'year_month', name: 'Mes y año' },
      { value: 'range', name: 'Rango' },
    ],
  },
  {
    key: 'year',
    title: 'Año',
    value: DEFAULT_FILTERS.year,
    type: 'select',
    list: YEAR_OPTIONS,
  },
  {
    key: 'month',
    title: 'Mes',
    value: DEFAULT_FILTERS.all,
    type: 'select',
    list: MONTH_OPTIONS,
    align: 'left',
  },
];

export default function AdminReportPage() {
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState<any>();

  const [appliedFilters, setAppliedFilters] = useState<Array<ITableFilter>>(defaultFilters);

  const filterType = useMemo(() => {
    const status = appliedFilters.find((filter) => filter.key === 'report_type');
    return status ? status.value.value : 'general';
  }, [appliedFilters]);

  const getRecord = async (filters?: Array<ITableFilter>) => {
    setLoading(true);
    let result: any = await generalApi.post('/records/stats', {
      filter: (filters || appliedFilters).reduce((acc: any, filter) => {
        acc[filter.key] = filter.value.value || filter.value;
        return acc;
      }, {}),
    });
    setLoading(false);
    if (!result.success) return showErrorToast(result.message);
    setStatsData(result.data);
  };

  const getCompanies = async () => {
    let result: any = await generalApi.get('/users/companies');
    if (!result.success) return showErrorToast(result.message);
    setAppliedFilters(
      appliedFilters.map((filter) =>
        filter.key === 'company'
          ? {
              ...filter,
              list: [
                { value: 'all', name: 'Todas' },
                ...result.data.items.map((obj: any) => ({
                  value: obj._id,
                  name: `${obj.ruc || ''} - ${obj.bussiness_name}`,
                })),
              ],
            }
          : filter
      )
    );
  };

  useEffect(() => {
    getRecord();
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-5xl px-1 py-8 text-[#1B205A] md:py-16 2xl:max-w-7xl">
        <h1 className="mb-8 text-3xl font-bold text-primary-base">REPORTE GENERAL</h1>
        <SelectFiltersComponent
          allFilters={allFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          getData={(filters) => getRecord(filters)}
        />
        <dl
          className={classNames(
            filterType === 'general' ? 'lg:grid-cols-5' : 'lg:grid-cols-4',
            'mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2'
          )}
        >
          {stats.map(
            (item) =>
              (filterType === 'general' || item.id !== 5) && (
                <div
                  key={item.id}
                  className="relative h-fit overflow-hidden rounded-lg border-2 border-primary-base bg-gray-50 px-4 pt-5 pb-3 shadow 2xl:px-6 2xl:pt-6"
                >
                  <dd
                    className={classNames(
                      statsData?.[item.field]?.length > 9
                        ? 'items-center lg:flex-col lg:items-start '
                        : 'items-center',
                      'flex gap-2'
                    )}
                  >
                    <div className="w-fit rounded-md bg-primary-base p-3 text-primary-base">
                      <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <p className="text-2xl font-semibold text-gray-900">
                      {statsData?.[item.field] || 0}
                    </p>
                  </dd>
                  <dt className="mt-2">
                    <p className="truncate font-medium text-gray-500">{item.name}</p>
                  </dt>
                </div>
              )
          )}
        </dl>
        <div className="mt-8 grid gap-8 md:grid-cols-12">
          <ShadowCard wrapperClassName="md:col-span-12" title="IMPORTES TOTALES">
            <LinearGraphComponent
              data1={{
                name:
                  filterType === 'general'
                    ? 'Importe total subsidio (C.R.)'
                    : 'Cantidad de expedientes',
                data: statsData?.dateTotals1 || [],
              }}
              data2={{
                name:
                  filterType === RECORD_STATUS.DISCOUNT
                    ? 'Importe descontado'
                    : filterType === RECORD_STATUS.NOT_RECOVERED
                    ? 'Importe no recuperado'
                    : [
                        RECORD_STATUS.IN_LOAN,
                        RECORD_STATUS.RESOLVED,
                        RECORD_STATUS.CHARGED,
                      ].includes(filterType)
                    ? 'Importe total subsidio (C.R.)'
                    : [RECORD_STATUS.NEW, RECORD_STATUS.IN_EXCHANGE].includes(filterType)
                    ? 'Importe considerado en planilla'
                    : 'Importe total EsSalud',
                data: statsData?.dateTotals2 || [],
              }}
              chartX={statsData?.dateLabels}
              isLoading={loading}
            />
          </ShadowCard>

          {filterType !== 'general' && (
            <ShadowCard wrapperClassName="md:col-span-12" title="IMPORTES POR STATUS">
              <LinearGraphComponent
                data1={{
                  name: 'Cantidad de expedientes',
                  data: statsData?.statusTotals1 || [],
                }}
                data2={{
                  name:
                    filterType === RECORD_STATUS.DISCOUNT
                      ? 'Importe descontado'
                      : filterType === RECORD_STATUS.NOT_RECOVERED
                      ? 'Importe no recuperado'
                      : [
                          RECORD_STATUS.IN_LOAN,
                          RECORD_STATUS.RESOLVED,
                          RECORD_STATUS.CHARGED,
                        ].includes(filterType)
                      ? 'Importe total subsidio (C.R.)'
                      : 'Importe considerado en planilla',
                  data: statsData?.statusTotals2 || [],
                }}
                chartX={
                  filterType === 'discount'
                    ? ['DESCUENTO']
                    : RECORD_STATUS_OPTIONS[filterType as RECORD_STATUS].map(
                        (obj) => RECORD_STATUS_DETAIL_TEXT[obj.value as RECORD_STATUS_DETAIL]
                      )
                }
                isLoading={loading}
              />
            </ShadowCard>
          )}

          {filterType === 'general' && (
            <>
              <ShadowCard
                wrapperClassName="md:col-span-6"
                title="CANTIDAD DE EXPEDIENTES POR TIPO DE STATUS"
              >
                <OptionsGraphComponent
                  title="STATUS"
                  color={OPTIONS_GRAPH_COLORS.ACCENT}
                  data={statsData?.statusGraph || {}}
                  total={statsData?.maxStatus || 0}
                  transform={(value) => RECORD_STATUS_TEXT[value as RECORD_STATUS]}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-6"
                title="CANTIDAD DE EXPEDIENTES POR TIPO DE CONTIGENCIA"
              >
                <OptionsGraphComponent
                  title="CONTINGENCIA"
                  color={OPTIONS_GRAPH_COLORS.YELLOW}
                  data={statsData?.contingencyGraph || {}}
                  total={statsData?.maxContingency || 0}
                  transform={(value) => value}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-6"
                title="CANTIDAD DE EXPEDIENTES POR ESPECIALIDAD MÉDICA"
              >
                <OptionsGraphComponent
                  title="ESPECIALIDAD MÉDICA"
                  color={OPTIONS_GRAPH_COLORS.PRIMARY}
                  data={statsData?.specialtyGraph || {}}
                  total={statsData?.maxSpecialty || 0}
                  transform={(value) => value}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-6"
                title="CANTIDAD DE EXPEDIENTES POR DIAGNÓSTICO GENERAL"
              >
                <OptionsGraphComponent
                  title="DIAGNÓSTICO GENERAL"
                  color={OPTIONS_GRAPH_COLORS.ACCENT}
                  data={statsData?.diagnosisGraph || {}}
                  total={statsData?.maxDiagnosis || 0}
                  transform={(value) => value}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-4"
                title="EXPEDIENTES SEGÚN SEXO DE COLABORADOR"
                contentClassName="flex justify-center"
              >
                <DoughnutGraphComponent
                  labels={['Femenino', 'Masculino']}
                  colors={['#B1CA56', '#3F92D1']}
                  data={statsData?.gender || []}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-4"
                title="EXPEDIENTE SEGÚN SU ESTADO FINAL"
                contentClassName="flex justify-center"
              >
                <DoughnutGraphComponent
                  labels={['Vigente', 'Cerrado']}
                  colors={['#3F92D1', '#E13832']}
                  data={statsData?.generalStatus || []}
                  isLoading={loading}
                />
              </ShadowCard>

              <ShadowCard
                wrapperClassName="md:col-span-4"
                title="EXPEDIENTE SEGÚN TIPO DE D.M."
                contentClassName="flex justify-center"
              >
                <DoughnutGraphComponent
                  labels={['EsSalud', 'Particular']}
                  colors={['#B1CA56', '#3F92D1']}
                  data={statsData?.dmType || []}
                  isLoading={loading}
                />
              </ShadowCard>
            </>
          )}
        </div>
      </div>
    </PrivateRoute>
  );
}
