import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import BaseInput from 'components/Inputs/BaseInput';
import Table from 'components/Table';

import {
  RECORD_CONSISTENCY_OPTIONS,
  RECORD_STATUS_DETAIL_TEXT,
  RECORD_STATUS_OPTIONS,
  RECORD_STATUS_TEXT,
  REQUIRED_RULE,
} from 'utils/constants';
import PaginationApi from 'utils/paginationApi';
import { formatDate, formatNumber } from 'utils/functions';
import { RECORD_STATUS, RECORD_STATUS_DETAIL } from 'utils/enums';
import { useAuth } from 'contexts/Auth';

const defaultFilter = {
  search: '',
  general_status: '',
  status: '',
  status_detail: '',
};

export default function DataExportPage() {
  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: defaultFilter,
  });
  const history = useNavigate();
  const auth = useAuth();

  const status = watch('status');

  const recordsApi = useMemo(
    () => new PaginationApi('/records/list', 10, auth, history),
    [auth, history]
  );

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [records, setRecords] = useState([]);

  const updateFilters = () => {
    setFilter(getValues());
  };

  const buildBaseInput = (
    label: string,
    type: React.HTMLInputTypeAttribute,
    name: string,
    rules: any,
    props: any
  ) => {
    return (
      <BaseInput
        label={label}
        placeholder={`Ingrese su ${label}`}
        type={type}
        name={name}
        register={register}
        errors={errors}
        watch={watch}
        control={control}
        rules={rules}
        {...props}
      />
    );
  };

  //useEffect(() => setFilter({ search }), [search]);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <h1 className="mb-4 text-3xl font-bold text-primary-base">DESCARGA DE INFORMACIÓN</h1>

        <div className="mb-8 grid gap-4 border-b border-gray-400 py-12 lg:grid-cols-3 lg:gap-8">
          {buildBaseInput('Estado General', 'select', 'general_status', REQUIRED_RULE, {
            options: [
              { value: 'valid', name: 'VIGENTE' },
              { value: 'closed', name: 'CERRADO' },
            ],
            onChange: (value: string) => {
              setValue('general_status', value);
              updateFilters();
            },
          })}
          {buildBaseInput('Status', 'select', 'status', REQUIRED_RULE, {
            options: [
              RECORD_STATUS.NEW,
              RECORD_STATUS.IN_EXCHANGE,
              RECORD_STATUS.IN_LOAN,
              RECORD_STATUS.RESOLVED,
              RECORD_STATUS.CHARGED,
              RECORD_STATUS.DISCOUNT,
              RECORD_STATUS.NOT_RECOVERED,
            ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
            onChange: (value: string) => {
              setValue('status', value);
              setValue('status_detail', undefined);
              updateFilters();
            },
          })}
          {status !== RECORD_STATUS.DISCOUNT &&
            buildBaseInput('Detalle de status', 'select', 'status_detail', REQUIRED_RULE, {
              options: RECORD_STATUS_OPTIONS[status as RECORD_STATUS],
              onChange: (value: string) => {
                setValue('status_detail', value);
                updateFilters();
              },
            })}
        </div>

        <Table
          isLoading={loading}
          setIsLoading={setLoading}
          loadingText="Buscando expedientes"
          columns={[
            {
              title: 'Código de expediente',
              dataKey: 'code',
              render: (item: any) => (
                <Link
                  to={`/record/${item.code}`}
                  className="text-primary-base underline hover:text-primary-dark"
                >
                  {item.code}
                </Link>
              ),
            },
            { title: 'Tipo de documento', dataKey: 'doc_type', hidden: true },
            {
              title: 'DNI/C.E.',
              dataKey: 'doc_number',
            },
            {
              title: 'Nombres y Apellidos',
              dataKey: 'name',
            },
            {
              title: 'Contingencia',
              dataKey: 'contingency',
              type: 'select',
              list: RECORD_CONSISTENCY_OPTIONS,
            },
            {
              title: 'Fecha Inicio',
              dataKey: 'dm_start_at',
              type: 'date',
              render: (item: any) =>
                item.dm_start_at ? formatDate(new Date(item.dm_start_at)) : '-',
            },
            {
              title: 'Fecha Fin',
              dataKey: 'dm_end_at',
              type: 'date',
              render: (item: any) => (item.dm_end_at ? formatDate(new Date(item.dm_end_at)) : '-'),
            },
            {
              title: 'Fecha Canje',
              dataKey: 'exchange_at',
              type: 'date',
              render: (item: any) =>
                item.exchange_at ? formatDate(new Date(item.exchange_at)) : '-',
            },
            {
              title: 'NIT Canje',
              dataKey: 'nitt',
            },
            {
              title: 'Fecha UPE',
              dataKey: 'loan_upe_at',
              type: 'date',
              render: (item: any) =>
                item.loan_upe_at ? formatDate(new Date(item.loan_upe_at)) : '-',
            },
            {
              title: 'Importe C.R.',
              dataKey: 'amount',
              type: 'number',
              render: (item: any) => (item.amount > 0 ? `S/ ${formatNumber(item.amount)}` : '-'),
            },
            {
              title: 'Fecha Pago',
              dataKey: 'charged_pay_at',
              type: 'date',
              render: (item: any) =>
                item.charged_pay_at ? formatDate(new Date(item.charged_pay_at)) : '-',
            },
            {
              title: 'Estado General',
              dataKey: 'general_status',
              hidden: true,
              type: 'select',
              list: [
                { value: 'valid', name: 'VIGENTE' },
                { value: 'closed', name: 'CERRADO' },
              ],
              render: (item: any) => (item.general_status === 'valid' ? 'VIGENTE' : 'CERRADO'),
            },
            {
              title: 'Status',
              dataKey: 'status',
              hidden: true,
              type: 'select',
              list: [
                RECORD_STATUS.NEW,
                RECORD_STATUS.IN_EXCHANGE,
                RECORD_STATUS.IN_LOAN,
                RECORD_STATUS.RESOLVED,
                RECORD_STATUS.NOT_RECOVERED,
                RECORD_STATUS.CHARGED,
                RECORD_STATUS.DISCOUNT,
              ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
              render: (item: any) =>
                item.status ? RECORD_STATUS_TEXT[item.status as RECORD_STATUS] : '-',
            },
            {
              title: 'Detalle de status',
              dataKey: 'status_detail',
              hidden: true,
              type: 'select',
              list: Object.values(RECORD_STATUS_OPTIONS).reduce(
                (acc, value) => [...acc, ...value],
                []
              ),
              render: (item: any) =>
                item.status
                  ? RECORD_STATUS_DETAIL_TEXT[item.status_detail as RECORD_STATUS_DETAIL]
                  : '-',
            },
            { title: 'Sede', dataKey: 'store', hidden: true },
            { title: 'Estado de trabajador', dataKey: 'worker_status', hidden: true },
            {
              title: 'Fecha de ingreso',
              dataKey: 'worker_start_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.worker_start_at ? formatDate(new Date(item.worker_start_at)) : '-',
            },
            {
              title: 'Fecha de cese',
              dataKey: 'worker_end_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.worker_end_at ? formatDate(new Date(item.worker_end_at)) : '-',
            },
            { title: 'Cargo', dataKey: 'position', hidden: true },
            { title: 'Teléfono', dataKey: 'phone', hidden: true },
            { title: 'Email', dataKey: 'email', hidden: true },
            { title: 'Sexo', dataKey: 'gender', hidden: true },
            { title: 'Remuneración diaria aprox', dataKey: 'daily_salary', hidden: true },
            { title: 'Tipo D.M', dataKey: 'dm_type', hidden: true },
            { title: 'Diagnóstico', dataKey: 'diagnosis', hidden: true },
            { title: 'Diagnóstico General', dataKey: 'general_diagnosis', hidden: true },
            { title: 'Especialidad', dataKey: 'specialty', hidden: true },
            { title: 'Centro médico', dataKey: 'center', hidden: true },
            { title: 'Nombre del médico', dataKey: 'doctor_name', hidden: true },
            { title: 'C.M.P', dataKey: 'cmp', hidden: true },
            {
              title: 'Fecha de emisión',
              dataKey: 'emitted_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.emitted_at ? formatDate(new Date(item.emitted_at)) : '-',
            },
            { title: 'Año de Subsidio', dataKey: 'subsidy_year', hidden: true },
            { title: 'Mes de Subsidio', dataKey: 'subsidy_month', hidden: true },
            { title: 'Días de Subsidio', dataKey: 'dm_days', hidden: true },
            {
              title: 'F. limite de canje',
              dataKey: 'exchange_deadline_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.exchange_deadline_at ? formatDate(new Date(item.exchange_deadline_at)) : '-',
            },
            {
              title: 'F. de comunicación al trabajador',
              dataKey: 'worker_comm_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.worker_comm_at ? formatDate(new Date(item.worker_comm_at)) : '-',
            },
            { title: 'N° de CITT', dataKey: 'citt', hidden: true },
            {
              title: 'F. de validación CITT',
              dataKey: 'citt_at',
              hidden: true,
              type: 'date',
              render: (item: any) => (item.citt_at ? formatDate(new Date(item.citt_at)) : '-'),
            },
            {
              title: 'F. de observación',
              dataKey: 'observation_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.observation_at ? formatDate(new Date(item.observation_at)) : '-',
            },
            {
              title: 'F. limite para subsanar',
              dataKey: 'fix_deadline_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.fix_deadline_at ? formatDate(new Date(item.fix_deadline_at)) : '-',
            },
            {
              title: 'F. de subsanación',
              dataKey: 'fix_at',
              hidden: true,
              type: 'date',
              render: (item: any) => (item.fix_at ? formatDate(new Date(item.fix_at)) : '-'),
            },
            { title: 'Validación de D.M - Comentario', dataKey: 'comment', hidden: true },
            {
              title: 'F. limite de prestaciones',
              dataKey: 'loan_deadline_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.loan_deadline_at ? formatDate(new Date(item.loan_deadline_at)) : '-',
            },
            { title: 'NIT de prestaciones', dataKey: 'loan_nitt', hidden: true },
            { title: 'Importe diario CR', dataKey: 'loan_cr_amount', hidden: true },
            {
              title: 'Importe total de subsidio (C.R.)',
              dataKey: 'loan_cr_total',
              hidden: true,
            },
            {
              title: 'Reembolso de subsidio - Comentario',
              dataKey: 'loan_comment',
              hidden: true,
            },
            {
              title: 'Fecha recepción resolución',
              dataKey: 'resolved_received_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.resolved_received_at ? formatDate(new Date(item.resolved_received_at)) : '-',
            },
            { title: 'N° de resolucion', dataKey: 'resolved_number', hidden: true },
            { title: 'Motivo resolucion', dataKey: 'resolved_motive', hidden: true },
            {
              title: 'F. de respuesta resolución',
              dataKey: 'resolved_response_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.resolved_response_at ? formatDate(new Date(item.resolved_response_at)) : '-',
            },
            {
              title: 'Gestión de prestaciones Resolución - Comentario',
              dataKey: 'resolved_comment',
              hidden: true,
            },
            {
              title: 'F. límite para cobrar subsidio',
              dataKey: 'charged_limit_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.charged_limit_at ? formatDate(new Date(item.charged_limit_at)) : '-',
            },
            {
              title: 'Fecha de cobro',
              dataKey: 'charged_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.charged_at ? formatDate(new Date(item.charged_at)) : '-',
            },
            { title: 'Importe EsSalud', dataKey: 'charged_essalud_amount', hidden: true },
            { title: 'Diferencia', dataKey: 'charged_difference', hidden: true },
            { title: 'N° expediente', dataKey: 'charged_number', hidden: true },
            { title: 'N° voucher', dataKey: 'charged_voucher', hidden: true },
            {
              title: 'Recepción de pago - Comentario',
              dataKey: 'charged_comment',
              hidden: true,
            },
            {
              title: 'F. límite solicitud de reintegro',
              dataKey: 'refund_deadline_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.refund_deadline_at ? formatDate(new Date(item.refund_deadline_at)) : '-',
            },
            { title: 'Importe Reintegro', dataKey: 'refund_amount', hidden: true },
            {
              title: 'F. de solicitud reintegro',
              dataKey: 'refund_at',
              hidden: true,
              type: 'date',
              render: (item: any) => (item.refund_at ? formatDate(new Date(item.refund_at)) : '-'),
            },
            { title: 'Nit solicitud de reintegro', dataKey: 'refund_nit', hidden: true },
            { title: 'N° expediente reintegro', dataKey: 'refund_number', hidden: true },
            {
              title: 'F. de pago reintegro',
              dataKey: 'refund_payment_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.refund_payment_at ? formatDate(new Date(item.refund_payment_at)) : '-',
            },
            {
              title: 'F. de cobro reintegro',
              dataKey: 'refund_collection_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.refund_collection_at ? formatDate(new Date(item.refund_collection_at)) : '-',
            },
            { title: 'N° voucher reintegro', dataKey: 'refund_voucher', hidden: true },
            { title: 'Reintegro - Comentario', dataKey: 'refund_comment', hidden: true },
            {
              title: 'F. descuento',
              dataKey: 'discount_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.discount_at ? formatDate(new Date(item.discount_at)) : '-',
            },
            { title: 'Importe descontado', dataKey: 'discount_amount', hidden: true },
            { title: 'Descuento - Comentario', dataKey: 'discount_comment', hidden: true },
            {
              title: 'F. pase a No recuperado',
              dataKey: 'not_recovered_at',
              hidden: true,
              type: 'date',
              render: (item: any) =>
                item.not_recovered_at ? formatDate(new Date(item.not_recovered_at)) : '-',
            },
            {
              title: 'Importe no recuperado',
              dataKey: 'not_recovered_amount',
              hidden: true,
            },
            {
              title: 'No recuperado - Comentario',
              dataKey: 'not_recovered_comment',
              hidden: true,
            },
          ]}
          initialSort={{
            key: 'dm_start_at',
            order: 'desc',
          }}
          data={records}
          setData={setRecords}
          dataApi={recordsApi}
          extraFilter={filter}
        />
      </div>
    </PrivateRoute>
  );
}
