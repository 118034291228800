import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isValid, parse } from 'date-fns';
import { read } from 'xlsx';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import {
  CheckIcon,
  DocumentAddIcon,
  DocumentReportIcon,
  DocumentSearchIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';

import LoadingSpinner from 'components/LoadingSpinner';
import { showErrorToast, showSuccessToast } from 'components/Toast';

import { sheetToArray, validateFile } from 'utils/fileUtils';
import { SPINNER_COLORS } from 'utils/enums';
import { classNames, formatDate } from 'utils/functions';
import GeneralApi from 'utils/generalApi';
import useLicense from 'hooks/useLicense';
import { useAuth } from 'contexts/Auth';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

export default function HomePage() {
  const history = useNavigate();
  const auth = useAuth();

  const [licenseLoading, licenseExpired, overLimit] = useLicense();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [workersLoadSuccess, setWorkersLoadSuccess] = useState(false);
  const [workers, setWorkers] = useState<any[]>([]);
  const [workerErrors, setWorkerErrors] = useState<any[]>([]);
  const [totalWorkers, setTotalWorkers] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(0);

  const checkFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let isValid = validateFile(event.target, 'excel');
    if (!isValid || !event.target?.files) {
      showErrorToast('Formato de documento no válido.');
      return undefined;
    }
    if (event.target.files[0].size > 10_000_000) {
      showErrorToast('Peso máximo excedido.');
      return undefined;
    }
    var reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e?.target?.result;
      if (!data) return undefined;
      const workbook = read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      handleFileData(sheetToArray(worksheet));
    };
    await reader.readAsArrayBuffer(event.target.files[0]);
  };

  async function handleFileData(list: Array<Array<any>>) {
    if (!list || list?.length === 0) return;
    if (list[0][0] !== 'Tipo de documento') return showErrorToast('Formato incorrecto');
    if (list.length < 2) return showErrorToast('Archivo vacío');

    const errors: any[] = [];
    const workers = list
      .splice(1, list.length - 1)
      .filter((row) => row.join('').length > 0)
      .map((row, index) => {
        const lineErrors = [];
        let [
          doc_type,
          doc_number,
          store,
          name,
          worker_status,
          worker_start_at,
          worker_end_at,
          position,
          phone,
          email,
          gender,
          daily_salary,
        ] = row;
        if (!['DNI', 'CE'].includes(doc_type?.toUpperCase())) {
          lineErrors.push('Tipo de documento no válido');
          doc_type = '';
        }
        if (!['ACTIVO', 'CESADO'].includes(worker_status?.toUpperCase())) {
          lineErrors.push('Estado de trabajador no válido');
          worker_status = '';
        }
        if (!doc_number) {
          lineErrors.push('Número de documento es obligatorio');
          doc_number = '';
        }
        const startAtValid = worker_start_at
          ? isValid(parse(worker_start_at, 'dd/MM/yyyy', new Date()))
          : true;
        const endAtValid = worker_end_at
          ? isValid(parse(worker_end_at, 'dd/MM/yyyy', new Date()))
          : true;
        if (!startAtValid) {
          lineErrors.push('Fecha de ingreso no válida');
          worker_start_at = '';
        } else {
          worker_start_at = parse(worker_start_at, 'dd/MM/yyyy', new Date());
        }
        if (!endAtValid) {
          lineErrors.push('Fecha de cese no válida');
          worker_end_at = '';
        } else {
          worker_end_at = parse(worker_end_at, 'dd/MM/yyyy', new Date());
        }
        if (lineErrors.length > 0) errors.push({ line: index + 1, errors: lineErrors });
        return {
          create: !!doc_number,
          doc_type: doc_type.toUpperCase(),
          doc_number: doc_number.toUpperCase(),
          store,
          name,
          worker_status: worker_status.toUpperCase(),
          worker_start_at,
          worker_end_at,
          position,
          phone,
          email,
          gender,
          daily_salary,
        };
      })
      .filter((worker) => worker.create);
    setWorkers(workers);

    if (errors.length > 0) {
      setWorkerErrors(errors);
      setShowErrorModal(true);
      return;
    } else {
      submitWorkers(workers);
    }
  }

  const submitWorkers = async (workersList?: any[]) => {
    setTotalWorkers((workersList || workers).length);
    setCurrentBatch(0);
    setUploadLoading(true);

    if ((workersList || workers).length > 100) {
      const batches = Math.ceil((workersList || workers).length / 100);
      for (let i = 0; i < batches; i++) {
        const batch = (workersList || workers).splice(0, 100);
        await saveWorkers(batch);
        setCurrentBatch(i + 1);
      }
    } else {
      const result = await generalApi.post(`/records/workers`, { workers: workersList || workers });
      if (!result?.success) {
        setUploadLoading(false);
        showErrorToast(result?.message);
        return;
      }
    }
    showSuccessToast('Datos guardados correctamente');
    setWorkersLoadSuccess(true);
    setUploadLoading(false);
  };

  const saveWorkers = async (workers: any[]) => {
    const result = await generalApi.post(`/records/workers`, { workers });
    return {
      success: !!result?.success,
    };
  };

  return (
    <PrivateRoute>
      <div className="text-[#1B205A]">
        <Modal
          isOpen={showErrorModal}
          onClose={() => {
            setShowErrorModal(false);
          }}
        >
          <div className="rounded-t-lg bg-red-500 px-4 pb-4 sm:flex sm:items-start sm:p-6 sm:py-4">
            <div className="pt-3 text-center sm:pt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-white" id="modal-headline">
                Errores en el archivo
              </h3>
            </div>
          </div>
          <div className="px-4 py-5 sm:py-6 sm:pl-6">
            <p>Las líneas en amarillo se guardarán con los campos erróneos vacíos.</p>
            <p>Las líneas en rojo no se guardarán.</p>
            <ul className="mt-2 flex max-h-64 flex-col gap-1 overflow-y-auto lg:max-h-96">
              {workerErrors.map((error, index) => (
                <li key={index} className="flex items-center gap-2">
                  {error.errors.includes('Número de documento es obligatorio') ? (
                    <div className="h-fit rounded-lg bg-red-100 p-1">
                      <XIcon className="h-6 w-6 text-red-500" />
                    </div>
                  ) : (
                    <div className="h-fit rounded-lg bg-yellow-100 p-1">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600" />
                    </div>
                  )}
                  <span className="text-sm">
                    Línea {error.line}: {error.errors.join(', ')}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <SolidButton
              size="sm"
              className="sm:ml-3 sm:w-auto"
              onClick={() => {
                setShowErrorModal(false);
                submitWorkers();
              }}
            >
              Guardar datos
            </SolidButton>
            <button
              type="button"
              onClick={() => setShowErrorModal(false)}
              className="focus:ring-primary-500 mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </Modal>
        <div className="flex h-full flex-col items-center justify-center py-16 lg:py-24">
          <h1 className="mb-8 text-center text-3xl font-bold lg:mb-20">
            GESTIÓN DE DESCANSOS MÉDICOS
          </h1>
          <div className="flex flex-col justify-around space-y-8 md:flex-row md:space-y-0 md:space-x-16">
            <button
              disabled={licenseLoading || licenseExpired || overLimit}
              className={classNames(
                licenseLoading || licenseExpired || overLimit
                  ? 'cursor-not-allowed'
                  : 'group cursor-pointer',
                'flex flex-col items-center rounded-lg transition-all duration-300 focus:p-1 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
              )}
              onClick={() => history('/new_record')}
            >
              <div className="mx-auto rounded-lg bg-gradient-to-tr from-blue-400 to-blue-600 p-6 transition-all duration-300 group-hover:p-8 group-focus:p-8">
                <DocumentAddIcon className="h-20 w-20" strokeWidth={1} />
              </div>
              <p className="mt-4 text-center text-lg text-gray-700 transition-all duration-300 group-hover:text-xl group-hover:text-blue-600 group-focus:text-xl group-focus:text-blue-600">
                NUEVO EXPEDIENTE
              </p>
              {licenseExpired && <p className="text-red-600">Licencia expirada</p>}
              {overLimit && <p className="text-red-600">Límite alcanzado</p>}
            </button>

            <button
              className="group flex cursor-pointer flex-col items-center rounded-lg transition-all duration-300 focus:p-1 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
              onClick={() => history('/records')}
            >
              <div className="mx-auto rounded-lg bg-gradient-to-tr from-green-400 to-green-600 p-6 transition-all duration-300 group-hover:p-8 group-focus:p-8">
                <DocumentSearchIcon className="h-20 w-20" strokeWidth={1} />
              </div>
              <p className="mt-4 text-center text-lg text-gray-700 transition-all duration-300 group-hover:text-xl group-hover:text-green-600 group-focus:text-xl group-focus:text-green-600">
                BUSCAR EXPEDIENTE
              </p>
            </button>

            <button
              className="group flex cursor-pointer flex-col items-center rounded-lg transition-all duration-300 focus:p-1 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2"
              onClick={() => history('/report')}
            >
              <div className="mx-auto rounded-lg bg-gradient-to-tr from-yellow-400 to-yellow-600 p-6 transition-all duration-300 group-hover:p-8 group-focus:p-8">
                <DocumentReportIcon className="h-20 w-20" strokeWidth={1} />
              </div>
              <p className="mt-4 text-center text-lg text-gray-700 transition-all duration-300 group-hover:text-xl group-hover:text-yellow-600 group-focus:text-xl group-focus:text-yellow-600">
                INDICADORES REPORTES
              </p>
            </button>

            <button
              className="group flex cursor-pointer flex-col items-center rounded-lg transition-all duration-300 focus:p-1 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
              onClick={() => history('/alerts')}
            >
              <div className="mx-auto rounded-lg bg-gradient-to-tr from-red-400 to-red-600 p-6 transition-all duration-300 group-hover:p-8 group-focus:p-8">
                <ExclamationIcon className="h-20 w-20" strokeWidth={1} />
              </div>
              <p className="mt-4 text-center text-lg text-gray-700 transition-all duration-300 group-hover:text-xl group-hover:text-red-600 group-focus:text-xl group-focus:text-red-600">
                ALERTAS
              </p>
            </button>
          </div>

          <div className="mx-auto mt-12 w-full max-w-5xl">
            <p className="text-xl">Carga de datos de colaboradores</p>
            <div className="mt-4 rounded-lg border-l-4 border-blue-400 bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="font-medium text-blue-800">DATOS IMPORTANTES</p>
                  <p className="font-medium text-blue-800">
                    Descargue el formato Excel
                    <a
                      href="https://externa-dev-files.s3.amazonaws.com/storage/colaboradores.xlsx"
                      target="_blank"
                      rel="noreferrer"
                      className="ml-1 font-medium text-blue-700 underline hover:text-blue-600"
                    >
                      aquí
                    </a>
                    .
                  </p>
                  <p className="text-base text-blue-800">- El número de documento es obligatorio</p>
                  <p className="text-base text-blue-800">- Tipo de documento: DNI, CE</p>
                  <p className="text-base text-blue-800">- Estado de trabajador: ACTIVO, CESADO</p>
                  <p className="text-base text-blue-800">
                    - Formato de fechas: DD/MM/YYYY ({formatDate(new Date(), 'dd/MM/yyyy')})
                  </p>
                </div>
              </div>
            </div>
            {workersLoadSuccess ? (
              <div className="mt-4 rounded-lg border-l-4 border-green-400 bg-green-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-green-800">
                      Carga de datos completada correctamente
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative mt-6 max-h-32 min-w-fit max-w-xs flex-1 rounded-md border-2 border-dashed border-gray-300 px-2 py-4 text-center">
                {uploadLoading && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/5">
                    <LoadingSpinner
                      content={
                        totalWorkers > 100
                          ? `Cargando ${currentBatch * 100} a ${
                              currentBatch === Math.ceil(workers.length / 100)
                                ? totalWorkers
                                : (currentBatch + 1) * 100
                            } de ${totalWorkers}`
                          : 'Cargando ...'
                      }
                      color={SPINNER_COLORS.PRIMARY}
                    />
                  </div>
                )}
                <p className={classNames(uploadLoading ? 'opacity-10' : '', 'mb-4')}>
                  Elegir archivo
                </p>
                <div
                  className={classNames(
                    uploadLoading ? 'opacity-10' : '',
                    'flex flex-col items-center justify-evenly sm:flex-row sm:space-y-0'
                  )}
                >
                  <div className="flex items-center space-x-2 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="text-sm text-gray-600">
                      <label
                        htmlFor="inp_document_file"
                        className="relative mx-auto cursor-pointer rounded-md bg-white font-medium text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-base focus-within:ring-offset-2 hover:text-primary-base"
                      >
                        <span>Escoger archivo</span>
                        <input
                          id="inp_document_file"
                          name="inp_document_file"
                          type="file"
                          className="sr-only"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={checkFile}
                        />
                      </label>
                      <p className="max-w-[120px] text-xs text-gray-500">de hasta 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
}
