import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import {
  DocumentDownloadIcon,
  DocumentTextIcon,
  DownloadIcon,
  PaperClipIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import LoadingSpinner from 'components/LoadingSpinner';
import OutlineButton from 'components/OutlineButton';

import { checkFileType, validateFile } from 'utils/fileUtils';
import { BUTTON_COLORS, SPINNER_COLORS } from 'utils/enums';
import { classNames, formatDate } from 'utils/functions';
import { IFileUploadComponent } from './interfaces';

export default function FileUploadComponent({
  generalApi,
  files,
  setFiles,
  folder,
  name,
}: IFileUploadComponent) {
  const [showFiles, setShowFiles] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null | undefined>(null);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const checkFile = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let isValid = validateFile(event.target, 'document', index);
    if (!isValid || !event.target?.files) {
      setImagePreview(null);
      showErrorToast('Formato de documento no válido.');
      return undefined;
    }
    if (event.target.files[index].size > 10_000_000) {
      setImagePreview(null);
      showErrorToast('Peso máximo excedido.');
      return undefined;
    }
    if (checkFileType(event.target.files[index].name) === 'image') {
      var reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setImagePreview(e?.target?.result);
      };
      await reader.readAsDataURL(event.target.files[index]);
    }
    return event.target.files[index];
  };

  async function saveFile(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const file = await checkFile(event, index);
    if (!file) return;

    let fileData = new FormData();

    if (folder) fileData.append('folder', folder);
    if (name) fileData.append('name', name);
    fileData.append('upfile', file);

    setUploadLoading(true);
    const result = await generalApi.post(`/utils/file`, fileData);
    setUploadLoading(false);
    if (!result?.success) {
      showErrorToast(result?.message);
      return;
    }
    showSuccessToast('Archivo guardado');
    setImagePreview(null);
    return result.data;
  }

  async function saveAllFiles(event: React.ChangeEvent<HTMLInputElement>) {
    const filesToUpload = event.target?.files;
    if (!filesToUpload) return;
    const newFiles = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const reponse = await saveFile(event, i);
      if (reponse) newFiles.push(reponse);
    }
    setFiles([...files, ...newFiles]);
  }

  const downloadAllFiles = async () => {
    const zip = new JSZip();
    setDownloadLoading(true);
    for (let i = 0; i < files.length; i++) {
      const file = await generalApi.getFile(files[i].url);
      if (file.data) zip.file(files[i].name, file.data);
    }
    const content = await zip.generateAsync({ type: 'blob' });
    setDownloadLoading(false);
    saveAs(content, 'documentos.zip');
  };

  useEffect(() => {
    if (files.length > 0) setShowFiles(true);
  }, [files]);

  return (
    <>
      <div className="flex gap-4">
        <OutlineButton className="mb-4 px-8 lg:w-auto" onClick={() => setShowFiles(!showFiles)}>
          <PaperClipIcon className="mr-2 h-6 w-6" />
          {showFiles && files.length > 0
            ? 'OCULTAR ARCHIVOS'
            : files.length > 0
            ? 'MOSTRAR ARCHIVOS'
            : 'ADJUNTAR ARCHIVOS'}
        </OutlineButton>
        {files.length > 0 && (
          <OutlineButton
            className="mb-4 px-8 lg:w-auto"
            isLoading={downloadLoading}
            onClick={() => downloadAllFiles()}
          >
            <DocumentDownloadIcon className="mr-2 h-6 w-6" />
            DESCARGAR ARCHIVOS
          </OutlineButton>
        )}
      </div>
      {showFiles && (
        <div className="mb-4 flex flex-wrap gap-8">
          <div className="relative max-h-32 min-w-fit max-w-xs flex-1 rounded-md border-2 border-dashed border-gray-300 px-2 py-4 text-center">
            {uploadLoading && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/5">
                <LoadingSpinner color={SPINNER_COLORS.PRIMARY} />
              </div>
            )}
            <p className={classNames(uploadLoading ? 'opacity-10' : '', 'mb-4')}>Elegir archivo</p>
            <div
              className={classNames(
                uploadLoading ? 'opacity-10' : '',
                'flex flex-col items-center justify-evenly sm:flex-row sm:space-y-0'
              )}
            >
              <div className="flex items-center space-x-2 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="text-sm text-gray-600">
                  <label
                    htmlFor="inp_document_file"
                    className="relative mx-auto cursor-pointer rounded-md bg-white font-medium text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-base focus-within:ring-offset-2 hover:text-primary-base"
                  >
                    <span>Escoger archivo</span>
                    <input
                      multiple
                      id="inp_document_file"
                      name="inp_document_file"
                      type="file"
                      className="sr-only"
                      onChange={saveAllFiles}
                    />
                  </label>
                  <p className="max-w-[120px] text-xs text-gray-500">de hasta 10MB</p>
                </div>
              </div>
              {imagePreview && (
                <img
                  src={imagePreview as string}
                  className="max-h-12 max-w-full rounded-lg border-2 border-primary-light sm:max-w-[50%]"
                  alt="Imagen del documento"
                ></img>
              )}
            </div>
          </div>
          {files.map((file) => (
            <div key={file.url} className="flex max-h-32 max-w-sm gap-2 rounded-lg p-4 shadow-card">
              {checkFileType(file.name) === 'image' ? (
                <img
                  className="h-24 rounded-lg border-2 border-primary-light"
                  src={file.url}
                  alt=""
                />
              ) : (
                <DocumentTextIcon className="mx-auto mb-2 h-12 w-12 text-gray-400" />
              )}
              <div className="flex flex-col items-end justify-between gap-1">
                <div className="text-right">
                  <p className="text-sm text-gray-600">{file.name}</p>
                  {file.uploaded_at && (
                    <p
                      className="text-sm text-gray-400"
                      title={formatDate(
                        new Date(file.uploaded_at),
                        `dd'/'MMMM'/'yyyy hh:mm a`,
                        false
                      )}
                    >
                      Subido el {formatDate(new Date(file.uploaded_at), `dd'/'MMMM'/'yyyy`, false)}
                    </p>
                  )}
                </div>
                <div className="flex gap-2">
                  <a href={file.url} target="_blank" rel="noreferrer">
                    <OutlineButton size="sm" className="w-auto" color={BUTTON_COLORS.PRIMARY}>
                      <DownloadIcon className="h-5 w-5" />
                    </OutlineButton>
                  </a>
                  <OutlineButton
                    size="sm"
                    className="w-auto"
                    color={BUTTON_COLORS.RED}
                    onClick={() => {
                      setFiles(files.filter((f) => f.url !== file.url));
                    }}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </OutlineButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
