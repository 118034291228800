import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import LandingHeader from 'components/Navigation/LandingHeader';
import InputErrorMessage from 'components/InputErrorMessage';
import LoadingSpinner from 'components/LoadingSpinner';

import LandingVideo from 'img/landing.mp4';
import LogoExterna from 'img/logo_externa.png';
import LogoWhite from 'img/logo_white@2x.png';

import SiemensLogo from 'img/logos/siemens.png';
import ValeLogo from 'img/logos/vale.png';
import SbsLogo from 'img/logos/sbs.png';
import TelefonicaLogo from 'img/logos/telefonica.jpg';
import MetsoLogo from 'img/logos/metso.png';
import SaviaLogo from 'img/logos/savia.png';
import BoticasLogo from 'img/logos/boticas.png';
import SiderLogo from 'img/logos/sider.png';
import IsaLogo from 'img/logos/isa.png';
import CofideLogo from 'img/logos/cofide.png';
import AfpLogo from 'img/logos/afp-habitat.png';
import CoestiLogo from 'img/logos/coesti.png';
import CalaminonLogo from 'img/logos/calaminon.png';
import PrimaxLogo from 'img/logos/primax.png';
import SesdermaLogo from 'img/logos/sesderma.png';
import JudicialLogo from 'img/logos/judicial.png';
import MineraLogo from 'img/logos/minera_corona.png';

import { ReactComponent as Step1 } from 'img/icons/step_1.svg';
import { ReactComponent as Step2 } from 'img/icons/step_2.svg';
import { ReactComponent as Step3 } from 'img/icons/step_3.svg';
import { ReactComponent as Step4 } from 'img/icons/step_4.svg';

import { ReactComponent as SearchIcon } from 'img/icons/search.svg';
import { ReactComponent as DashboardIcon } from 'img/icons/dashboard.svg';
import { ReactComponent as DownloadIcon } from 'img/icons/download.svg';
import { ReactComponent as AlertsIcon } from 'img/icons/alerts.svg';
import { ReactComponent as CriticalIcon } from 'img/icons/critical.svg';
import { ReactComponent as GraphIcon } from 'img/icons/graphs.svg';
import { ReactComponent as WebIcon } from 'img/icons/web.svg';
import { ReactComponent as RemoteIcon } from 'img/icons/remote.svg';
import { ReactComponent as DBIcon } from 'img/icons/db.svg';
import { ReactComponent as PrivacyIcon } from 'img/icons/privacy.svg';

import { ReactComponent as FacebookLogo } from 'img/icons/facebook.svg';
import { ReactComponent as LinkedinLogo } from 'img/icons/linkedin.svg';
import { ReactComponent as InstagramLogo } from 'img/icons/instagram.svg';

import { ReactComponent as MailIcon } from 'img/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'img/icons/phone.svg';

import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

export default function LandingPage() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const history = useNavigate();
  const auth = useAuth();
  const formRef = useRef<any>(null);

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [loading, setLoading] = useState(false);
  const [rangeValue, setRangeValue] = useState('10');
  const [rangeCost, setRangeCost] = useState(50);
  const [rangeMax, setRangeMax] = useState(50);

  const steps = [
    {
      title: 'Crea',
      content:
        'Crea tus expedientes visualízalos en la Plataforma de manera online desde donde te encuentres',
      image: (
        <div className="mx-auto flex aspect-square h-32 w-32 items-center justify-center rounded-full bg-gradient-to-br from-blue-400 to-blue-600">
          <Step1 className="mx-auto h-16" />
        </div>
      ),
    },
    {
      title: 'Carga',
      content: 'Resguarda y archiva de forma segura los sustentos de cada expediente',
      image: (
        <div className="mx-auto flex aspect-square h-32 w-32 items-center justify-center rounded-full bg-gradient-to-br from-green-400 to-green-600">
          <Step2 className="mx-auto h-16" />
        </div>
      ),
    },
    {
      title: 'Alertas',
      content: 'Obtén alertas inmediatas para un seguimiento controlado de tus expedientes',
      image: (
        <div className="mx-auto flex aspect-square h-32 w-32 items-center justify-center rounded-full bg-gradient-to-br from-red-400 to-red-600">
          <Step3 className="mx-auto h-12" />
        </div>
      ),
    },
    {
      title: 'Reportes e indicadores',
      content:
        'Genera reportes, informes y estadísticas en tiempo real, según la información que se va gestionando',
      image: (
        <div className="mx-auto flex aspect-square h-32 w-32 items-center justify-center rounded-full bg-gradient-to-br from-yellow-400 to-yellow-600">
          <Step4 className="mx-auto h-16" />
        </div>
      ),
    },
  ];

  const features = [
    {
      icon: <SearchIcon className="h-16" />,
      text: 'Búsqueda de expedientes, adjuntos y sustentos de forma rápida y óptima',
    },
    {
      icon: <DashboardIcon className="h-16" />,
      text: 'Gestión de la información mediante dashboard para toma de decisiones',
    },
    {
      icon: <DownloadIcon className="h-16" />,
      text: 'Descarga de información cuando necesites donde estés',
    },
    {
      icon: <AlertsIcon className="h-16" />,
      text: 'Recibirás notificaciones vía correo para evitar pérdidas de tus expedientes',
    },
    {
      icon: <CriticalIcon className="h-16" />,
      text: 'Tendrás el control de aquellos casos críticos con semaforización',
    },
    {
      icon: <GraphIcon className="h-16" />,
      text: 'Menor inversión con mayor productividad',
    },
    {
      icon: <WebIcon className="h-16" />,
      text: 'No necesita instalación',
    },
    {
      icon: <RemoteIcon className="h-16" />,
      text: 'Acceso remoto desde cualquier lugar',
    },
    {
      icon: <DBIcon className="h-16" />,
      text: 'Backup, mantenimiento y actualizaciones automáticas',
    },
    {
      icon: <PrivacyIcon className="h-16" />,
      text: 'Confidencialidad y seguridad de tu información, sólo tu tendrás acceso',
    },
  ];

  const submitContact = async (data: any) => {
    setLoading(true);
    let result: any = await generalApi.post('/contacts', data);
    setLoading(false);
    if (!result.success) return showErrorToast(result.message);
    showSuccessToast('Datos enviados');
    reset();
  };

  useEffect(() => {
    const getLicense = async () => {
      let result: any = await generalApi.get('/licenses');
      if (!result.success) return showErrorToast(result.message);
      setRangeCost(result.data.license.cost || 10);
      setRangeMax(result.data.license.max_records || 500);
    };
    getLicense();
  }, [generalApi, setRangeCost]);

  //1c6641
  //145d38
  //197949

  return (
    <div className="scroll-pt-32 text-[#1B205A]">
      <LandingHeader />
      <section id="home_anchor" className="anchor relative overflow-hidden bg-[#1c6641] bg-cover">
        <div className="absolute left-1/4 -top-[60%] aspect-square h-[200%] rounded-full bg-[#145d38]" />
        <div className="absolute -top-[15%] -right-[150%] aspect-square h-[105%] rounded-full bg-[#197949] sm:-right-[75%] lg:-right-[30%] lg:-top-[30%]" />

        <div className="relative z-10 items-center justify-evenly px-4 pt-20 pb-32 lg:flex lg:gap-4 lg:pt-48 xl:pt-64 xl:pb-40">
          <video
            className="mx-auto mt-16 w-full max-w-2xl rounded-xl lg:mx-0 lg:mt-0 lg:w-1/2 2xl:w-2/5"
            src={LandingVideo}
            loop
            muted
            controls
            autoPlay
          >
            Your browser does not support the video tag.
          </video>
          <div className="relative mt-12 flex max-w-4xl flex-col items-center text-white sm:pt-0 lg:mt-0 lg:w-1/2">
            <h2 className="mx-auto max-w-lg text-center text-2xl font-bold xl:max-w-full xl:text-3xl">
              SUBSIDY, LA PLATAFORMA TECNOLÓGICA
            </h2>
            <h2 className="mx-auto max-w-lg text-center text-2xl xl:max-w-full xl:text-3xl">
              QUE OPTIMIZA LOS PROCESOS DE LA GESTIÓN DE SUBSIDIOS POR DESCANSOS MÉDICOS
            </h2>

            {/* <div className="mx-2 mt-12 max-w-md rounded-xl bg-white px-4 py-6 text-[#1B205A] shadow-card sm:mx-auto sm:p-8">
              <p className="text-center text-3xl text-primary-base">ENTERATE DE MÁS</p>
              <input
                type="text"
                placeholder="Nombre"
                className="mt-12 h-12 w-full rounded-lg border border-gray-300"
              />
              <input
                type="text"
                placeholder="Celular"
                className="mt-2 h-12 w-full rounded-lg border border-gray-300"
              />
              <button className="mt-6 w-full rounded-lg bg-primary-base px-12 py-2 text-white transition-colors hover:bg-primary-base">
                ENVIAR MIS DATOS
              </button>
            </div> */}
            <button
              className="mx-auto mt-12 rounded-lg bg-[#FBDD03] px-16 py-3 text-lg text-gray-900 transition-all duration-300 hover:scale-110 hover:bg-opacity-90"
              onClick={() =>
                formRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            >
              COTIZA CON NOSOTROS
            </button>
          </div>
        </div>
      </section>

      <section className="bg-white text-center">
        <div className="mx-auto max-w-[100rem] py-24 px-4 lg:px-16">
          <h2 id="platform_anchor" className="xs:text-5xl mb-6 text-4xl font-bold">
            PLATAFORMA SUBSIDY
          </h2>
          <p className="mx-auto text-2xl">
            ¡Te permitirá mejorar tu proceso de trabajo manteniendo la trazabilidad de tus
            expedientes!
          </p>
          <p className="mx-auto mt-4 text-2xl font-medium text-[#1C6641]">
            ¡NO MÁS PÉRDIDA DE SUBSIDIOS!
          </p>
          <div className="mt-16 grid gap-8 sm:grid-cols-2 xl:grid-cols-4 xl:gap-16">
            {steps.map((step, index) => (
              <div key={index} className="rounded-2xl bg-white p-5 pb-12 shadow-card">
                {step.image}
                <p className="mt-6 text-4xl">{step.title}</p>
                <p className="mt-4 text-lg">{step.content}</p>
              </div>
            ))}
          </div>
          <p className="mx-auto mt-16 text-2xl">
            Al tener la información centralizada es mucho más fácil su búsqueda.
          </p>
        </div>
      </section>

      <section className="relative bg-[#F7F7F7] px-8 py-20 text-center sm:px-16 sm:py-32">
        <h2 id="pricing_anchor" className="xs:text-5xl mb-4 text-4xl font-bold">
          PRECIOS
        </h2>
        <p className="text-xl">Bienvenido al cotizador de Subsidy</p>

        <div className="mx-auto mt-8 max-w-7xl rounded-lg bg-white px-8 pt-12 pb-16 text-left shadow-xl lg:mt-16 lg:px-16">
          <div className="relative pt-1">
            <input
              type="range"
              className="h-2 w-full appearance-none rounded-lg bg-gray-400 p-0 focus:shadow-none focus:outline-none focus:ring-0"
              min="0"
              max={rangeMax}
              step={rangeMax > 100 ? 10 : 5}
              value={rangeValue}
              onChange={(e) => setRangeValue(Number(e.target.value) > 0 ? e.target.value : '1')}
            />
            <p
              className="absolute top-8 text-2xl font-bold"
              style={{
                left: `${(Number(rangeValue) * 100) / rangeMax}%`,
                transform: `translate(${-(Number(rangeValue) * 100) / rangeMax}%, 2px)`,
              }}
            >
              {rangeValue}
            </p>
            <div className="mt-20 flex flex-col gap-8 lg:flex-row">
              <div className="lg:w-1/2">
                <label htmlFor="inp_cost" className="text-xl font-medium text-primary-base">
                  Número de expedientes
                </label>
                <p className="text-sm">
                  Indica la cantidad estimada de expedientes que generas en un año
                </p>
                <input
                  id="inp_cost"
                  type="number"
                  className="mt-2 h-14 w-full max-w-xs rounded-lg border border-gray-300 text-2xl focus:border-primary-base"
                  value={rangeValue}
                  max={rangeMax}
                  onChange={(e) => {
                    if (Number(e.target.value) > rangeMax) {
                      return setRangeValue(rangeMax.toString());
                    }
                    setRangeValue(Number(e.target.value) > 0 ? e.target.value : '1');
                  }}
                />
              </div>

              <div className="lg:w-1/2">
                <label htmlFor="inp_cost" className="text-xl font-medium text-primary-base">
                  Valor de Subsidy Anual{' '}
                  <span className="font-light text-gray-800">(sin I.G.V)</span>
                </label>
                <input
                  readOnly
                  id="inp_cost"
                  type="text"
                  className="mt-2 h-14 w-full max-w-xs rounded-lg border border-gray-300 text-2xl focus:border-primary-base lg:mt-6"
                  value={`${(Number(rangeValue) * rangeCost).toFixed(2)} USD`}
                />
              </div>
            </div>
          </div>
        </div>

        <p className="mt-8 text-xl">Agenda una reunión para una cotización personalizada</p>
        <div className="flex justify-center">
          <button
            className="mt-6 rounded-lg bg-[#FBDD03] px-12 py-3 transition-colors hover:bg-opacity-80"
            onClick={() => formRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            AGENDA UNA REUNIÓN
          </button>
        </div>
      </section>

      <section
        id="features_anchor"
        className="anchor relative mt-48 bg-[#F7F7F7] pb-12 text-center sm:mt-0 sm:pt-60 sm:pb-32 lg:pb-32"
      >
        <div className="absolute inset-x-0 top-0 bg-[#1C6641] pt-28 pb-48">
          <h2 className="text-center text-4xl font-bold text-white">VENTAJAS</h2>
        </div>
        <div className="relative z-10 mx-auto grid max-w-7xl gap-6 px-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 xl:gap-y-16 2xl:gap-x-12 2xl:px-0">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-4 rounded-xl bg-white px-4 py-8 shadow-[0px_5px_15px_#00000029]"
            >
              {feature.icon}
              <p className="mt-2 text-lg">{feature.text}</p>
            </div>
          ))}
        </div>
      </section>

      <section
        id="customers_anchor"
        className="anchor relative pt-28 pb-12 text-center sm:py-32 lg:pb-32"
      >
        <div className="mb-20 text-center">
          <h2 className="text-4xl font-bold">CLIENTES</h2>
        </div>
        <div className="mx-auto flex max-w-7xl flex-wrap justify-center">
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={SiemensLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={ValeLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={SbsLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={TelefonicaLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={MetsoLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={SaviaLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={BoticasLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={SiderLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={IsaLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={CofideLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={AfpLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={CoestiLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={CalaminonLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={PrimaxLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={SesdermaLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={JudicialLogo} alt="" />
          </div>
          <div className="flex w-1/2 items-center justify-center px-6 py-8 lg:w-1/5">
            <img src={MineraLogo} alt="" />
          </div>
        </div>
      </section>

      <section className="relative flex items-center justify-center overflow-hidden bg-[#1C6641] py-12 text-white xl:block xl:h-80 xl:py-0">
        <div className="absolute -left-[100%] -top-[150%] aspect-square h-[300%] rounded-full bg-[#197949] sm:-left-[15%] sm:-top-[150%]" />

        <div className="relative bottom-0 left-0 z-10 max-w-6xl pb-4 xl:absolute xl:ml-8 2xl:ml-32">
          <h2
            id="contact_anchor"
            className="xs:text-5xl max-w-lg text-center text-4xl font-bold xl:mb-6 xl:text-left"
          >
            AHORRA TIEMPO EN LOS PROCESOS Y CÉNTRATE EN EL BIENESTAR DE LAS PERSONAS
          </h2>
        </div>
      </section>

      <div className="relative pt-8 xl:pl-8 2xl:pl-32">
        <p className="mx-4 max-w-md text-xl xl:mx-0">
          Nuestro equipo de profesionales <span className="font-bold">atenderá a tu solicitud</span>{' '}
          de la manera más <span className="font-bold">rápida y oportuna.</span>
        </p>
        <p className="mx-4 mt-4 max-w-md text-xl xl:mx-0 xl:mb-80">
          Horario de atención: Lunes a Viernes de 9:00 a.m – 6:00p.m.
        </p>
        <form
          ref={formRef}
          className="right-32 -top-64 mx-auto my-8 max-w-xl border border-t-gray-200 bg-white p-4 shadow-card md:rounded-lg md:border-0 xl:absolute xl:my-0 xl:p-8"
          onSubmit={handleSubmit(submitContact)}
        >
          <div className="grid space-y-4 lg:grid-cols-2 lg:space-y-0 lg:space-x-4">
            <div>
              <label htmlFor="inp_names">Nombres</label>
              <input
                id="inp_names"
                type="text"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('name', { required: 'Este campo es obligatorio' })}
              />
              <InputErrorMessage error={errors['name']} />
            </div>
            <div>
              <label htmlFor="inp_last_name">Apellidos</label>
              <input
                id="inp_last_name"
                type="text"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('last_name', { required: 'Este campo es obligatorio' })}
              />
              <InputErrorMessage error={errors['last_name']} />
            </div>
          </div>
          <div className="mt-4 grid space-y-4 lg:grid-cols-2 lg:space-y-0 lg:space-x-4">
            <div>
              <label htmlFor="inp_company">Empresa</label>
              <input
                id="inp_company"
                type="text"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('company', { required: 'Este campo es obligatorio' })}
              />
              <InputErrorMessage error={errors['company']} />
            </div>
            <div>
              <label htmlFor="inp_location">País / Región</label>
              <input
                id="inp_location"
                type="text"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('location', { required: 'Este campo es obligatorio' })}
              />
              <InputErrorMessage error={errors['location']} />
            </div>
          </div>
          <div className="mt-4 grid space-y-4 lg:grid-cols-2 lg:space-y-0 lg:space-x-4">
            <div>
              <label htmlFor="inp_phone">Teléfono Móvil</label>
              <input
                id="inp_phone"
                type="text"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('phone', { required: 'Este campo es obligatorio' })}
              />
              <InputErrorMessage error={errors['phone']} />
            </div>
            <div>
              <label htmlFor="inp_workers">Cantidad de Trabajadores</label>
              <input
                id="inp_workers"
                type="number"
                className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
                {...register('workers', { required: 'Este campo es obligatorio' })}
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="inp_email">Email</label>
            <input
              id="inp_email"
              type="text"
              className="mt-1 h-12 w-full rounded-lg border border-gray-300 focus:border-primary-base"
              {...register('email', { required: 'Este campo es obligatorio' })}
            />
            <InputErrorMessage error={errors['email']} />
          </div>
          <div className="mt-4">
            <label htmlFor="inp_comments">Comentarios</label>
            <textarea
              id="inp_comments"
              rows={3}
              className="mt-1 w-full rounded-lg border border-gray-300 focus:border-primary-base"
              {...register('comment', { required: 'Este campo es obligatorio' })}
            />
            <InputErrorMessage error={errors['comment']} />
          </div>
          <div className="relative mt-2 flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="terms"
                aria-describedby="terms-description"
                name="terms"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:ring-primary-base"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="text-gray-700">
                He leído y acepto los términos y condiciones de las políticas de protección y
                protección de base de datos.
              </label>
            </div>
          </div>
          <button
            className="mt-6 rounded-lg bg-[#FBDD03] px-12 py-3 transition-colors hover:bg-opacity-90 lg:w-1/2"
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : 'ENVIAR'}
          </button>
        </form>
      </div>

      <div className="bg-[#3D3D3D] py-12 px-4 text-white lg:px-8 2xl:px-16">
        <div className="space-y-16 lg:flex lg:space-y-0 lg:space-x-24">
          <div className="space-y-4">
            <p className="text-3xl">PÁGINAS</p>
            <a className="block text-lg hover:underline" href="#home_anchor">
              Inicio
            </a>
            <a className="block text-lg hover:underline" href="#platform_anchor">
              Nuestro servicio
            </a>
            <a className="block text-lg hover:underline" href="#contact_anchor">
              Contáctanos
            </a>
          </div>
          <div className="space-y-4">
            <p className="text-3xl">REDES SOCIALES</p>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/Externa.asesores" target="_blank" rel="noreferrer">
                <FacebookLogo className="h-8 w-8" />
              </a>
              <a
                href="https://www.linkedin.com/in/externa-per%C3%BA/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo className="h-8 w-8" />
              </a>
              <a href="https://www.instagram.com/externa_pe" target="_blank" rel="noreferrer">
                <InstagramLogo className="h-8 w-8" />
              </a>
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-3xl">INFORMACIÓN</p>
            <div className="flex items-center space-x-2">
              <MailIcon className="h-6 w-6" />
              <p className="text-lg">comercial@externaasesores.com</p>
            </div>
            <div className="flex items-center space-x-2">
              <PhoneIcon className="h-6 w-6" />
              <div>
                <p className="text-lg">933 678 479</p>
                <p className="text-lg">997 139 901</p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-8 h-0.5 w-full bg-white" />

        <div className="items-center justify-between text-sm lg:flex">
          <div className="items-center lg:flex">
            <img src={LogoExterna} className="mr-1 h-10" alt="" />
            <img src={LogoWhite} className="mr-4 h-8 border-l border-white pl-3" alt="" />
            <p className="mt-4 lg:mt-0">
              Copyright © 2020-2021 Externa Todos los derechos reservados.
            </p>
          </div>
          <div className="mt-4 space-y-4 lg:mt-0 lg:flex lg:space-x-2 lg:space-y-0 2xl:space-x-4">
            <p>Términos y condiciones</p>
            <p>Política de privacidad</p>
            <p>Política de cookies</p>
            <p>Notificaciones de Copyright</p>
          </div>
        </div>
      </div>
    </div>
  );
}
