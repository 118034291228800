import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { showErrorToast } from 'components/Toast';

import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';

export default function useLicense() {
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [licenseLoading, setLicenseLoading] = useState(false);
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [overLimit, setOverLimit] = useState(false);
  const [licenseError, setLicenseError] = useState(false);

  useEffect(() => {
    const getLicense = async () => {
      setLicenseLoading(true);
      const result: any = await generalApi.get('/users/license');
      setLicenseLoading(false);
      if (!result.success) {
        setLicenseError(true);
        return showErrorToast(result.message);
      }
      setOverLimit(result.data.overLimit);
      setLicenseExpired(result.data.noLicense);
    };
    getLicense();
  }, [generalApi]);

  return [licenseLoading, licenseExpired, overLimit, licenseError];
}
