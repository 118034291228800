import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';
import AdminLayout from './AdminLayout';
import StorageService from 'utils/storage';

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    StorageService.remove('userKind');
    return <Navigate replace to="/login" state={{ from: location }} />;
  }
  StorageService.set('userKind', auth.isAdmin ? 'admin' : 'customer');
  return <AdminLayout>{children}</AdminLayout>;
}
