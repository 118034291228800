import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

import { classNames } from 'utils/functions';
import { BUTTON_COLORS } from 'utils/enums';

export const colorClasses = {
  primary: 'bg-primary-base focus:bg-primary-dark hover:bg-primary-dark focus:ring-primary-base',
  blue: 'bg-blue-700 focus:bg-blue-600 hover:bg-blue-800 focus:ring-blue-500',
  red: 'bg-red-600 focus:bg-red-500 hover:bg-red-700 focus:ring-red-500',
};

const sizeClasses = {
  sm: 'py-1 px-4',
  md: 'py-2 px-4 sm:text-lg',
  lg: 'py-3 px-4 sm:text-lg',
};

export default function SolidButton({
  color = BUTTON_COLORS.PRIMARY,
  className = '',
  onClick,
  isLoading,
  isDisabled,
  size = 'md',
  children,
}: {
  color?: BUTTON_COLORS;
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
}) {
  return (
    <button
      data-testid="solid-button"
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(
        className,
        colorClasses[color],
        sizeClasses[size],
        isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        'inline-flex w-full select-none items-center justify-center rounded-lg border border-transparent text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2'
      )}
    >
      {isLoading ? <LoadingSpinner /> : children}
    </button>
  );
}
