import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'components/Navigation/ProtectedRoute';
import ChangeLicenseModalContent from './ChangeModalContent';
import { showErrorToast } from 'components/Toast';
import SolidButton from 'components/SolidButton';
import LicenseManageForm from './ManageForm';
import Table from 'components/Table';
import Modal from 'components/Modal';

import { ICompany, ILicense } from 'utils/interfaces';
import { formatDate } from 'utils/functions';
import GeneralApi from 'utils/generalApi';
import { useAuth } from 'contexts/Auth';
import { endOfDay, isBefore, startOfDay } from 'date-fns';

export default function LicensesPage() {
  const history = useNavigate();
  const auth = useAuth();

  const generalApi = useMemo(() => new GeneralApi(auth, history), [auth, history]);

  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Array<ICompany>>([]);

  const [isActionOpen, setIsActionOpen] = useState(false);
  const [actionData, setActionData] = useState<{ action: string; data: ILicense | ICompany }>();

  useEffect(() => {
    const getCompanies = async () => {
      setLoading(true);
      let result: any = await generalApi.get('/users/companies');
      setLoading(false);
      setRefresh(false);
      if (!result.success) return showErrorToast(result.message);
      setCompanies(result.data.items);
    };
    if (refresh) {
      getCompanies();
    }
  }, [refresh, generalApi]);

  return (
    <PrivateRoute>
      <div className="mx-auto h-full max-w-7xl px-1 py-8 text-[#1B205A] lg:py-16">
        <Modal
          isOpen={isActionOpen}
          onClose={() => {
            setIsActionOpen(false);
            setActionData(undefined);
          }}
        >
          <ChangeLicenseModalContent
            title="Cambiar licencia"
            actionData={actionData?.data as ICompany}
            onClose={() => {
              setIsActionOpen(false);
              setActionData(undefined);
            }}
            onSuccess={() => {
              setRefresh(true);
              setIsActionOpen(false);
              setActionData(undefined);
            }}
          />
        </Modal>
        <div className="mb-3 flex items-center justify-between">
          <h1 className="text-3xl font-bold text-primary-base">LICENCIAS</h1>
        </div>

        <LicenseManageForm />

        <div className="mb-3 mt-12 flex items-center justify-between">
          <h1 className="text-3xl font-bold text-primary-base">CLIENTES</h1>
        </div>

        <Table
          isLoading={loading}
          setIsLoading={setLoading}
          loadingText="Buscando empresas"
          columns={[
            {
              title: 'RUC',
              dataKey: 'ruc',
              disableSort: true,
            },
            {
              title: 'Nombre',
              dataKey: 'bussiness_name',
              disableSort: true,
            },
            {
              title: 'Email',
              dataKey: 'email',
            },
            {
              title: 'Fecha de término',
              dataKey: 'license.date',
              render: (item: ICompany) =>
                item.license?.date ? (
                  <p
                    className={
                      isBefore(startOfDay(new Date(item.license.date)), endOfDay(new Date()))
                        ? 'text-red-500'
                        : ''
                    }
                  >
                    {formatDate(new Date(item.license?.date), 'dd/MM/yyyy', true)}
                  </p>
                ) : (
                  ''
                ),
            },
            {
              title: 'Cantidad de Expedientes',
              dataKey: 'license.yearly_record_count',
              disableSort: true,
              render: (item: ICompany) =>
                item.license ? (
                  <p
                    className={
                      (item.yearly_record_count || 0) >= item.license.max_records
                        ? 'text-red-500'
                        : ''
                    }
                  >{`${item.yearly_record_count || 0} (Hasta ${item.license.max_records})`}</p>
                ) : (
                  ''
                ),
            },
            {
              title: 'Costo',
              dataKey: 'license.cost',
              render: (item: ICompany) =>
                item.license?.cost ? `$ ${item.license.cost.toFixed(2)}` : '-',
            },
            {
              title: 'IGV',
              dataKey: 'license.igv',
              render: (item: ICompany) =>
                item.license?.cost ? `$ ${(item.license.cost * 0.18).toFixed(2)}` : '-',
            },
            {
              title: 'Total',
              dataKey: 'license.total',
              render: (item: ICompany) =>
                item.license?.cost ? `$ ${(item.license.cost * 1.18).toFixed(2)}` : '-',
            },
            {
              title: 'Editar',
              dataKey: 'edit',
              render: (item: ICompany) => (
                <div>
                  <SolidButton
                    size="sm"
                    onClick={() => {
                      setActionData({
                        action: 'editCompany',
                        data: {
                          _id: item._id,
                          license: item.license,
                        },
                      });
                      setIsActionOpen(true);
                    }}
                  >
                    Editar
                  </SolidButton>
                </div>
              ),
            },
          ]}
          data={companies}
          setData={setCompanies}
        />
      </div>
    </PrivateRoute>
  );
}
