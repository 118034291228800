import { es } from 'date-fns/esm/locale';
import Holidays from 'date-holidays';
import {
  addBusinessDays,
  addMinutes,
  endOfDay,
  format,
  isAfter,
  isBefore,
  startOfDay,
  isSameDay,
  getDay,
} from 'date-fns';

export function classNames(...classes: Array<string | undefined>) {
  return classes.filter(Boolean).join(' ');
}

export function formatDate(dateString: Date, formatString = `dd/MM/yyyy`, isUTC = true) {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return format(isUTC ? addMinutes(date, date.getTimezoneOffset()) : date, formatString, {
    locale: es,
  });
}

export function addWorkDays(date: Date, days: number) {
  const holidays = new Holidays('PE').getHolidays();
  const startDate = addMinutes(date, date.getTimezoneOffset());
  const newDate = addBusinessDays(startDate, days - 1);

  const holidaysCont = holidays.filter(
    (holiday) =>
      isAfter(endOfDay(holiday.start), startOfDay(startDate)) &&
      isBefore(startOfDay(holiday.start), endOfDay(newDate)) &&
      ![0, 6].includes(getDay(startOfDay(holiday.start)))
  ).length;

  let finalDay = addBusinessDays(newDate, holidaysCont || 0);
  let loop = holidays.some((holiday) => isSameDay(holiday.start, finalDay));
  while (loop) {
    finalDay = addBusinessDays(finalDay, 1);
    for (let i = 0; i < holidays.length; i++) loop = isSameDay(holidays[i].start, finalDay);
  }

  return finalDay;
}

export function scrollToTop() {
  document?.querySelector('#scroller')?.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const cleanObject = (params: any, onlyNull = false) => {
  Object.keys(params).map((key) => {
    if (onlyNull) {
      if (params[key] === null) delete params[key];
    } else {
      if (!params[key]) delete params[key];
    }
    return key;
  });
  return params;
};

export const cleanString = (dirtyString: string) => dirtyString.replace(/[|&;$%@"<>()+,]/g, '');

export const getObjectValue = (obj: any, path: string) => {
  const paths = path.split('.');
  let current = obj;
  for (let i = 0; i < paths.length; i++) {
    if (current[paths[i]]) {
      current = current[paths[i]];
    } else {
      return null;
    }
  }
  return current;
};

export const formatNumber = (number: number) =>
  !number ? '' : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
