import React, { createContext, useContext, useState } from 'react';

import { API_URL } from 'utils/constants';
import StorageService from '../utils/storage';
import axios from 'axios';

export interface IAuthContext {
  prefix?: string;
  isAuthenticated: boolean;
  isAdmin: boolean;
  signin: (username: String, password: string) => void;
  enter: (companyId: string) => void;
  signout: (callback: () => void) => void;
  refreshUserData: () => void;
}

const AuthContext = createContext<IAuthContext>({
  prefix: '',
  isAuthenticated: false,
  isAdmin: false,
  signin: () => {},
  enter: () => {},
  signout: () => {},
  refreshUserData: () => {},
});

export function ProvideAuth({
  prefix = '',
  children,
}: {
  prefix?: string;
  children: React.ReactNode;
}) {
  const auth = useProvideAuth(prefix);
  return <AuthContext.Provider value={{ prefix, ...auth }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth(prefix: string) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    StorageService.get('userData', prefix)?._id !== undefined
  );
  const [isAdmin, setIsAdmin] = useState(
    StorageService.get('userData', prefix)?.position === 'super_admin'
  );

  const signin = async (username: String, password: string) => {
    try {
      const result = await axios.post(
        `${API_URL}/users/login`,
        {
          username,
          password,
        },
        {
          headers: {
            'api-key': process.env.REACT_APP_API_KEY || '',
          },
        }
      );
      const isSuperAdmin = result.data.user.position === 'super_admin';
      StorageService.set('accessToken', result.data.accessToken, isSuperAdmin ? 'admin' : prefix);
      if(result.data?.companies){
        return {
          success: true,
          message: 'Selecciona la empresa a la que deseas ingresar',
          data: result.data,
        };
      }
      StorageService.set('userData', result.data.user, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('isAuthenticated', true, isSuperAdmin ? 'admin' : prefix);
      //StorageService.set('accessToken', result.data.accessToken, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('refreshToken', result.data.refreshToken, isSuperAdmin ? 'admin' : prefix);
      setIsAuthenticated(true);
      setIsAdmin(isSuperAdmin);
      return {
        success: true,
        message: 'Inicio de sesión completado, bienvenido',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      StorageService.set('userData', null, prefix);
      setIsAuthenticated(false);
      setIsAdmin(false);
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  };
  const enter = async (companyId: string) => {
    const userKind = StorageService.get('userKind') === 'admin' ? 'admin' : undefined;
    try {
      const result = await axios.post(
        `${API_URL}/users/login/outsourcing`,
        {
          companyId
        },
        {
          headers: {
            'authorization': StorageService.get('accessToken', userKind) ,
          },
        }
      );
      if(result.data?.companies){
        return {
          success: true,
          message: 'Selecciona la empresa a la que deseas ingresar',
          data: result.data,
        };
      }
      console.log(result.data);
      
      const isSuperAdmin = result.data.user.position === 'super_admin';
      StorageService.set('userData', result.data.user, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('isAuthenticated', true, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('accessToken', result.data.accessToken, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('refreshToken', result.data.refreshToken, isSuperAdmin ? 'admin' : prefix);
      StorageService.set('api_key', result.data.api_key, isSuperAdmin ? 'admin' : prefix);
      setIsAuthenticated(true);
      setIsAdmin(isSuperAdmin);
      return {
        success: true,
        message: 'Inicio de sesión completado, bienvenido',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      StorageService.set('userData', null, prefix);
      setIsAuthenticated(false);
      setIsAdmin(false);
      localStorage.clear()
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  };

  const refreshUserData = async () => {
    try {
      const result = await axios.post(
        `${API_URL}/user`,
        {
          user: StorageService.get('userData', prefix)._id,
        },
        {
          headers: {
            'api-key': process.env.REACT_APP_API_KEY || '',
          },
        }
      );
      StorageService.set('userData', result.data.user, prefix);
      return {
        success: true,
        message: 'Usuario verificado, bienvenido',
        data: result.data,
      };
    } catch (error: any) {
      console.log(error);
      return {
        success: false,
        message: error.response != null ? error.response.data.message : 'Ocurrió un error',
      };
    }
  };

  const signout = (callback: () => void) => {
    StorageService.set('userData', null, prefix);
    StorageService.set('isAuthenticated', false, prefix);
    StorageService.set('accessToken', null, prefix);
    StorageService.set('refreshToken', null, prefix);
    setIsAuthenticated(false);
    setIsAdmin(false);
    localStorage.clear()
    callback();
  };

  return {
    isAuthenticated,
    isAdmin,
    signin,
    enter,
    signout,
    refreshUserData,
  };
}
