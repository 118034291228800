import React, { Fragment } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/functions';

const CustomSelect = ({
  name,
  value,
  control,
  options,
  rules,
  disabled,
  position = 'bottom',
  onChange,
}: {
  name: string;
  value: string;
  control: Control<FieldValues, any>;
  options: Array<{ value?: string; name: string }>;
  rules?: any;
  disabled?: boolean;
  position?: 'bottom' | 'top';
  onChange?: (value: any) => void;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field }) => (
        <Listbox {...field} disabled={disabled} {...(onChange ? { onChange: onChange } : {})}>
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button
                  className={classNames(
                    disabled ? 'text-gray-400' : 'text-gray-900',
                    'relative block h-6 w-full border-0 p-0 text-left placeholder-gray-500 focus:outline-none focus:ring-0'
                  )}
                >
                  <span className="block truncate">
                    {options?.find((obj) => (obj.value ? obj.value : obj.name) === value)?.name}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={classNames(
                      position === 'bottom' ? 'top-8' : 'bottom-12',
                      'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    )}
                  >
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-primary-base text-white' : 'text-gray-900',
                          'relative h-8 cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value=""
                    />
                    {options?.map((option) => (
                      <Listbox.Option
                        key={option.value ? option.value : option.name}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-primary-base text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={option.value ? option.value : option.name}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {option.name ? option.name : option.value}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-base',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    />
  );
};

export default CustomSelect;
