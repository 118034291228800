import React from 'react';
import { PencilAltIcon, SaveIcon } from '@heroicons/react/outline';

import OutlineButton from 'components/OutlineButton';

import { BUTTON_COLORS, RECORD_STEPS } from 'utils/enums';

export default function StepTitle({
  title,
  loading,
  step,
  currentStep,
  setCurrentStep,
  onSubmit,
}: {
  title: string;
  loading: boolean;
  step: RECORD_STEPS;
  currentStep?: RECORD_STEPS;
  setCurrentStep?: any;
  onSubmit?: any;
}) {
  return (
    <div className="items-center justify-between md:flex">
      <span className="text-xl font-bold">{title}</span>
      {onSubmit && (
        <div className="mt-1 flex items-center justify-end space-x-4 text-sm md:mt-0 md:justify-center md:text-lg">
          {currentStep !== step ? (
            <OutlineButton
              className="w-auto"
              isDisabled={currentStep !== undefined}
              onClick={() => setCurrentStep(step)}
            >
              Editar
              <PencilAltIcon className="ml-2 h-4 w-4" />
            </OutlineButton>
          ) : (
            <>
              <OutlineButton
                color={BUTTON_COLORS.RED}
                isDisabled={loading}
                onClick={() => setCurrentStep(undefined)}
              >
                Cancelar
              </OutlineButton>
              <OutlineButton isLoading={loading} onClick={() => onSubmit()}>
                Guardar
                <SaveIcon className="ml-2 h-4 w-4" />
              </OutlineButton>
            </>
          )}
        </div>
      )}
    </div>
  );
}
