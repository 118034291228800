import React from 'react';
import {Link} from 'react-router-dom';

import LogoColor from 'img/logo_color@2x.png';

export default function ExternalHeader() {
  return (
    <div className="relative bg-white px-4 py-6 shadow-md sm:items-stretch sm:px-12">
      <Link to="/">
        <img src={LogoColor} className="mx-auto h-16" alt="" />
      </Link>
    </div>
  );
}
